<div class="px-4">
  <div class="row">
    <div class="col">
      <h4>Summary</h4>
    </div>
  </div>
  <div>
    <div class="row kehe-data-section">
      <div class="col-12 col-md-6 mt-3">
        <div class="row">
          <div class="col-12 col-sm-6 kehe-data-label">
            Supplier Type
          </div>
          <div class="col-12 col-sm-6 kehe-data-value">
            {{ getSupplierType() | emptyValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 kehe-data-label">
            Contract Date
          </div>
          <div class="col-12 col-sm-6 kehe-data-value">
            {{getDateDisplayValue(terms?.contractDate)}}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 kehe-data-label">
            Change Date
          </div>
          <div class="col-12 col-sm-6 kehe-data-value">
            {{getDateDisplayValue(terms?.changeDate)}}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <div class="row">
          <div class="col-12 col-sm-6 kehe-data-label">Terms Form Exemption</div>
          <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasTermsFormExemption| displayYesNo}}</div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 kehe-data-label">Payment Hold</div>
          <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasProcurementHold| displayAcceptsExcludedProgram}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class ="px-4">
  <div class="row">
    <div class="col">
      <h4>Audits</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Audits</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasStatementAudit | displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Audit Timeframe</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getAuditTimeFrame()}}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Promotion Allowance Audits</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasPromoAllow | displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Promotional Price Protection Audits</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasPromoPriceProtect | displayYesNo}}</div>
      </div>
    </div>
  </div>
</div>
<hr/>
<div class="px-4">
  <div class="row">
    <div class="col">
      <h4>Item Requirements</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Shelf Life</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getPercentDisplayValue(terms?.shelfLifePercentage)}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Cut Cases</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.acceptsCutCaseFee | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">New Product Setup Fee</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.acceptsNewItemSetupFee | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row" *ngIf="terms?.acceptsNewItemSetupFee">
        <div class="col-12 col-sm-6 kehe-data-label">New Product Setup Fee</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getCurrencyDisplayValue(terms?.newItemSetupFee)}}</div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Product Imaging Charge</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.acceptsImagingCharge | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">New Product Intro Allowance</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.acceptsIntroAllowance | displayAcceptsExcludedProgram}}
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class ="px-4">
  <div class="row">
    <div class="col">
      <h4>Payment Terms and Pricing</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Payment Terms</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getNullDefaultDisplayValue(terms?.paymentTerms?.description)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Payment Method</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getPaymentMethod()}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Due Date Calculation</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getDueDateCalculation(terms?.dueDateCalculation)}}</div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Special Event Terms (Seasonal)</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getSpecialEventTermsSeasonal()}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Special Event Terms (New Warehouse Opening)</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getSpecialEventTermsNewWarehouseOpening()}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Price Change Notification</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getPriceChangeNoticeInDays()}}
        </div>
      </div>
    </div>

  </div>
</div>
<hr>
<div class ="px-4">
  <div class="row">
    <div class="col">
      <h4>Shipping and Receiving</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Lumping Fees</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasLumpingFee | displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Pallet Charges</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasPalletCharge| displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Pallet Charge in $$</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getCurrencyDisplayValue(terms?.palletChargeAmount)}}</div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Fill Rate Fee</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasFillRateFee| displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Order Overage 30%</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.hasThirtyPercentOrderCoverage| displayYesNo}}</div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class ="px-4">
  <div class="row">
    <div class="col">
      <h4>Marketing and Promotions</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Placement Allowances (Free Fill)</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getFreeProductsProgram()}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Free Fill Handling Charge</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{getFreeProductHandlingCharge()}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">KeHE CONNECT BI Program</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getNullDefaultDisplayValue(terms?.promotions?.marketingAllowance?.description)}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Extra Performance</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.promotions?.hasExtraPerformance | displayYesNo}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Publication Listing Fees</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{terms?.promotions?.hasPublicationListingFee | displayYesNo}}</div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">MCB Processing Fee</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.promotions?.acceptsManufacturerChargeBackFee | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row" *ngIf="canShowMCBExceptionCode">
        <div class="col-12 col-sm-6 kehe-data-label m-0 d-flex align-items-center">
          <div>MCB Exception Code</div>
          <em class="fas fa-info-circle info-tooltip" [keheTooltip]="tooltipMCBExceptionCode"></em>
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getMCBExceptionValue()}}</div>
      </div>
      <div class="row" *ngIf="canShowMCBFeeDollars">
        <div class="col-12 col-sm-6 kehe-data-label">MCB Fee Dollars</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getCurrencyDisplayValue(terms?.promotions?.manufacturerChargeBackFeeAmount)}}</div>
      </div>
      <div class="row" *ngIf="canShowMCBFeePercentage">
        <div class="col-12 col-sm-6 kehe-data-label">MCB Fee Percentage</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getPercentDisplayValue(terms?.promotions?.manufacturerChargeBackFeePercentage)}}</div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class ="px-4">
  <div class="row">
    <div class="col">
      <h4>Retail Marketplace Expenses</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Retailer Reclamation</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.hasRetailerReclamation | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Retailer Discontinued</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.hasRetailerDiscontinuedProgram | displayAcceptsExcludedProgram}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">KeHE Discontinued</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.hasKeheDiscount | displayAcceptsExcludedProgram}}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Spoils</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getSpoilsValue(terms?.spoils)}}</div>
      </div>
      <div class="row" *ngIf="showSpoilagePercent()">
        <div class="col-12 col-sm-6 kehe-data-label">Spoilage Allowance Percent</div>
        <div class="col-12 col-sm-6 kehe-data-value">{{getSpoilagePercentDisplayValue(terms?.spoils?.allowancePercentage, '1.2-2')}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">Warehouse Spoils</div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{terms?.acceptsWarehouseOutOfDateCharge | displayAcceptsExcludedProgram}}
        </div>
      </div>
    </div>
  </div>
</div>


