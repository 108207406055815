import { Injectable } from '@angular/core';
import { SupplierDetail } from '../models/supplier-detail';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressService } from '@kehe/phoenix-address';
import {
  getSupplierCategoryManagerFullName,
  getSupplierDevelopmentManagerFullName,
  isActive,
  isAddedDraftSupplier,
  isDraft,
  isInvitedDraftSupplier
} from '../utilities/supplier-utils';
import { TermsFormObject } from '../models/terms-form-object';
import { SpoilsTypes } from '../models/spoils-types';
import { prepPhone } from '../../../utils/common-utils';
import { isNull, omitBy } from 'lodash';
import { Terms } from '../models/terms';
import { PromotionTerms } from '../models/promotion-terms';
import { TermsDropdownData, TermsDropdownType } from '../models/terms-dropdown-data';
import { invalidTermsDropdownValue } from '../utilities/custom-form-validations';
import { Constants } from '../../../constants/constants';
import { SupplierCurrenciesComboBox } from '@app/modules/supplier-list/constants/supplier-constants';

@Injectable({
  providedIn: 'root'
})
export class SupplierFormService {

  public allowancePercentageFieldReadOnly = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _addressService: AddressService,
  ) { }

  getGeneralDefaultForm(supplier: SupplierDetail) {
    const headquarterAddressForm = this._addressService.createDefaultAddressFormGroup();
    headquarterAddressForm.get('name').disable();
    return this.getGeneralForm(supplier, headquarterAddressForm);
  }

  getGeneralForm(supplier: SupplierDetail, headquarterAddressForm: UntypedFormGroup) {
    const form = this._formBuilder.group({
      name: [supplier.name, [Validators.required]],
      wmsLegalName: [supplier.wmsLegalName, [
        Validators.required,
        Validators.pattern('[ -~]+'),
        Validators.maxLength(50),
      ]],
      esn: [supplier.esn],
      doingBusinessAs: [supplier.doingBusinessAs],
      categoryManager: this._formBuilder.group({
        code: [supplier.categoryManagerCode, [Validators.required]],
        name: [getSupplierCategoryManagerFullName(supplier)],
      }),
      supplierDevelopmentManager: this._formBuilder.group({
        code: [supplier.supplierDevelopmentManagerCode, [Validators.required]],
        name: [getSupplierDevelopmentManagerFullName(supplier)],
      }),
      addedSupplierRepresentative: this._formBuilder.group({
        name: [supplier?.addedSupplierRepresentative?.name, [Validators.required]],
        email: [supplier?.addedSupplierRepresentative?.email, [Validators.required, Validators.email]],
      }),
      headquarterAddressId: [supplier.headquarterAddressId, Validators.required],
      headquarterAddress: headquarterAddressForm,
      headquarterPhone: [supplier.headquarterAddress ? prepPhone(supplier.headquarterAddress.phone) : ''],
      headquarterExtension: [supplier.headquarterAddress ? supplier.headquarterAddress.extension : ''],
      headquarterFax: [supplier.headquarterAddress ? prepPhone(supplier.headquarterAddress.fax) : ''],
      headquarterEmail: [supplier.headquarterAddress ? supplier.headquarterAddress.email : '', [Validators.email]],
      isEdi: [supplier.isEdi],
      hasInternationalCurrency: [supplier.hasInternationalCurrency, Validators.nullValidator],
      supplierCurrencyValue: [supplier.supplierCurrency ? supplier.supplierCurrency : SupplierCurrenciesComboBox.find(item => item.code === 'USD'), Validators.nullValidator],
      tradingPartnerId: [supplier.tradingPartnerId, Validators.required],
      keheShipToRetailerDate: [supplier.keheShipToRetailerDate],
      updatedBySource: ['Enterprise']
    });

    if (!isAddedDraftSupplier(supplier)) {
      form.get('addedSupplierRepresentative').disable();
    }
    this.patchGeneralForm(supplier, form, headquarterAddressForm);

    this.updateGeneralFormValidations(form);
    if (isInvitedDraftSupplier(supplier)) {
      this.disableAddressFields(form);
    } else {
      form.get('headquarterAddressId').disable();
    }
    return form;
  }

  disableAddressFields(form) {
    form.get('headquarterAddress').disable();
    form.get('headquarterPhone').disable();
    form.get('headquarterFax').disable();
    form.get('headquarterExtension').disable();
    form.get('headquarterEmail').disable();
  }

  updateGeneralFormValidations(form: UntypedFormGroup) {
    if (!form.controls.isEdi.value) {
      form.get('tradingPartnerId').disable();
    } else {
      form.get('tradingPartnerId').enable();
    }
    if (!this._addressService.isInternational(form.get('headquarterAddress').value)) {
      form.get('headquarterAddress.state').setValidators([Validators.required]);
      form.get('headquarterAddress.province').reset();
    } else {
      form.get('headquarterAddress.state').clearValidators();
      form.get('headquarterAddress.state').reset();
    }
    form.get('headquarterAddress.state').updateValueAndValidity({ emitEvent: false });
    form.get('headquarterAddress.province').updateValueAndValidity({ emitEvent: false });
  }

  getTermsDefaultForm(supplier: SupplierDetail, termsDropdownData: TermsDropdownData[]) {
    return this.getTermsForm(supplier, termsDropdownData);
  }

  getTermsForm(supplier: SupplierDetail, termsDropdownData: TermsDropdownData[]) {
    let form: UntypedFormGroup;
    form = this.getTermsFormVFM(supplier, termsDropdownData);
    form.get('terms.spoils.spoilsType').valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        this.setSpoilsAllowancePercentageValidations(form, value);
      }
    });
    form.addControl('supplierType', new UntypedFormControl(supplier.supplierType, [Validators.required]));
    if (!isDraft(supplier)) {
      form.get('supplierType').disable();
    }
    return form;
  }

  getTermsFormVFM(supplier: SupplierDetail, termsDropdownData: TermsDropdownData[]) {
    return this._formBuilder.group({
      esn: [supplier.esn],
      terms: this._formBuilder.group({
        contractDate: [supplier.terms ? supplier.terms.contractDate : null, [Validators.required]],
        changeDate: [new Date(), [Validators.required]],
        hasTermsFormExemption: [{value: isActive(supplier) ? false : null, disabled: isActive(supplier)}, [Validators.required]],
        hasProcurementHold: [null, [Validators.required]],
        dueDateCalculation: [null, [invalidTermsDropdownValue(termsDropdownData, TermsDropdownType.DueDateCalculation)]],
        specialEventTermsSeasonalCode: [null, [Validators.required]],
        specialEventTermsNewWarehouseOpeningCode: [null, [Validators.required]],
        priceChangeNoticeInDays: [null, [Validators.required]],
        acceptsNewItemSetupFee: [null, [Validators.required]],
        hasStatementAudit: [null, [Validators.required]],
        hasPromoAllow: [null, [Validators.required]],
        hasPromoPriceProtect: [null, [Validators.required]],
        auditTimeFrameCode: [null, [Validators.required]],
        newItemSetupFee: [],
        acceptsCutCaseFee: [null, [Validators.required]],
        acceptsImagingCharge: [null, [Validators.required]],
        acceptsWarehouseOutOfDateCharge: [null, [Validators.required]],
        acceptsIntroAllowance: [null, [Validators.required]],
        shelfLifePercentage: [null, [Validators.required, Validators.min(50), Validators.max(100)]],
        paymentTerms: this._formBuilder.group({
          code: [null, [this.isValidString]],
          description: [],
        }),
        paymentMethodCode: [null, [Validators.required]],
        spoils: this._formBuilder.group({
          allowancePercentage: [null,
            [
              Validators.required,
              Validators.min(.00),
              Validators.max(100.00)
            ]
          ],
          spoilsType: [null, [Validators.required]],
        }),
        hasRetailerDiscontinuedProgram: [null, [Validators.required]],
        hasRetailerReclamation: [null, [Validators.required]],
        hasKeheDiscount: [null, [Validators.required]],
        hasLumpingFee: [null, [Validators.required]],
        hasFillRateFee: [null, [Validators.required]],
        hasThirtyPercentOrderCoverage: [null, [Validators.required]],
        hasPalletCharge: [null, [Validators.required]],
        palletChargeAmount: [null, [Validators.required, Validators.min(0), Validators.max(20)]],
        promotions: this._formBuilder.group({
          acceptsManufacturerChargeBackFee: [null, [Validators.required]],
          manufacturerChargeBackExceptionCode: [],
          manufacturerChargeBackFeeAmount: [],
          manufacturerChargeBackFeePercentage: [],
          freeProductsProgramCode: [null, [Validators.required]],
          freeProductHandlingChargeCode: [null, [Validators.required]],
          hasPublicationListingFee: [null, [Validators.required]],
          hasExtraPerformance: [null, [Validators.required]],
          marketingAllowance: this._formBuilder.group({
            code: [null, [Validators.required]],
            description: [],
          })
        })
      }),
      updatedBySource: ['Enterprise']
    });
  }

  patchTermsFormUpdates(terms: TermsFormObject, form: UntypedFormGroup) {
    form.patchValue(terms);
    this.updateTermsFormValidations(form);
  }

  setPalletChargeAmount(form: UntypedFormGroup): void {
    const palletChargeAmountField = form.get('terms.palletChargeAmount');
    if (this.hasPalletCharge(form)) {
      palletChargeAmountField.setValidators([Validators.required, Validators.max(10), Validators.min(0.01)]);
    } else {
      palletChargeAmountField.setValue(0);
      this.clearFieldValidators(palletChargeAmountField);
    }
    palletChargeAmountField.updateValueAndValidity();
    palletChargeAmountField.markAsDirty();
  }

  setMCBExceptionCodeValidators(form: UntypedFormGroup) {
    const mcbExceptionCode = form.get('terms.promotions.manufacturerChargeBackExceptionCode');
    if (this.excludeMCBFee(form)) {
      this.clearFieldValidators(mcbExceptionCode);
    } else {
      mcbExceptionCode.setValidators([Validators.required]);
    }
    mcbExceptionCode.updateValueAndValidity({ emitEvent: false});
  }

  setMCBExceptionCodeValues(supplier: SupplierDetail, form: UntypedFormGroup) {
    const mcbExceptionCode = form.get('terms.promotions.manufacturerChargeBackExceptionCode');
    if (this.excludeMCBFee(form)) {
      mcbExceptionCode.setValue(null);
    }
    this.setMCBFeePercentageValues(supplier, form);
    this.setMCBFeeAmountValues(supplier, form);
  }

  private patchGeneralForm(supplier: SupplierDetail, form: UntypedFormGroup, headquarterAddressForm: UntypedFormGroup) {
    if (supplier) {
      const compactedSupplier = omitBy<SupplierDetail>(supplier, isNull);
      form.patchValue(compactedSupplier);
    }
    if (supplier.headquarterAddress) {
      headquarterAddressForm.patchValue(supplier.headquarterAddress);
    }
  }

  private excludeMCBFee(form: UntypedFormGroup) {
    return !form.get('terms.promotions.acceptsManufacturerChargeBackFee').value;
  }

  private hasPalletCharge(form: UntypedFormGroup) {
    return form.get('terms.hasPalletCharge').value;
  }

  private clearFieldValidators(control: AbstractControl): void {
    control.setErrors(null);
    control.clearValidators();
  }

  public isValidString(control: UntypedFormControl) {
    const isValid = control.value !== null && control.value !== undefined;
    return isValid ? null : { 'required': true };
  }

  private setFormFieldValue(form: UntypedFormGroup, fieldName, value: any) {
    form.get(`${fieldName}`).setValue(value);
  }

  private convertFieldToDecimal(form: UntypedFormGroup, fieldName) {
    const formField = form.get(`${fieldName}`);
    if (formField.value !== null) {
      if (!isNaN(Number(formField.value))) {
        const formattedNumber = Number(Number(formField.value * 100).toFixed(2));
        this.setFormFieldValue(form, fieldName, formattedNumber);
      }
    }
  }

  private convertDecimalFields(form: UntypedFormGroup) {
    this.convertFieldToDecimal(form, 'terms.shelfLifePercentage');
  }

  private setMCBFeePercentageValues(supplier: SupplierDetail, form: UntypedFormGroup) {
    if (supplier?.terms) {
      const promotionTerms = supplier.terms.promotions;
      const mcbFeePercentage = form.get('terms.promotions.manufacturerChargeBackFeePercentage');
      if (this.excludeMCBFee(form)) {
        mcbFeePercentage.setValue(null);
        mcbFeePercentage.markAsDirty();
        return;
      }
      if (promotionTerms?.manufacturerChargeBackFeePercentage) {
        mcbFeePercentage.setValue(promotionTerms.manufacturerChargeBackFeePercentage);
      } else {
        mcbFeePercentage.setValue(Constants.MCBFeePercentage);
      }
      mcbFeePercentage.markAsDirty();
    }
  }

  private setMCBFeeAmountValues(supplier: SupplierDetail, form: UntypedFormGroup) {
    if (supplier?.terms) {
      const promotionTerms = supplier.terms.promotions;
      const mcbFeeAmount = form.get('terms.promotions.manufacturerChargeBackFeeAmount');
      if (this.excludeMCBFee(form)) {
        mcbFeeAmount.setValue(null);
        mcbFeeAmount.markAsDirty();
        return;
      }
      if (promotionTerms?.manufacturerChargeBackFeeAmount) {
        mcbFeeAmount.setValue(promotionTerms.manufacturerChargeBackFeeAmount);
      } else {
        mcbFeeAmount.setValue(Constants.MCBFeeAmount);
      }
      mcbFeeAmount.markAsDirty();
    }
  }

  private setSpoilsAllowancePercentageValidations(form: UntypedFormGroup, spoilsType) {
    const allowancePercentageField = form.get('terms.spoils.allowancePercentage');
    if (spoilsType === SpoilsTypes.spoilageAllowance) {
      this.allowancePercentageFieldReadOnly = false;
      allowancePercentageField.setValidators([
        Validators.min(.01),
        Validators.max(100.00),
        Validators.required,
      ]);
      allowancePercentageField.markAsTouched();
      allowancePercentageField.updateValueAndValidity();
    } else {
      this.allowancePercentageFieldReadOnly = true;
      allowancePercentageField.setValidators([
        Validators.required
      ]);
      allowancePercentageField.setValue(0.00);
      allowancePercentageField.markAsDirty();
    }
  }

  public patchTermsForm(supplier: SupplierDetail, form: UntypedFormGroup) {
    const compactedTerms = omitBy<Terms>(supplier.terms, isNull);
    if (compactedTerms.promotions) {
      const compactedPromotions = omitBy<PromotionTerms>(compactedTerms.promotions, isNull);
      delete compactedTerms.promotions;
      form.get('terms.promotions').patchValue(compactedPromotions);
    }
    form.get('terms').patchValue(compactedTerms);
    this.convertDecimalFields(form);
    this.setMCBExceptionCodeValues(supplier, form);
    this.updateTermsFormValidations(form);
  }

  private updateTermsFormValidations(form: UntypedFormGroup) {
    this.setMCBExceptionCodeValidators(form);
    this.setSpoilsAllowancePercentageValidations(form, form.get('terms.spoils.spoilsType').value);
  }

  getBrokerForm(supplier: SupplierDetail) {
    const form: UntypedFormGroup = this._formBuilder.group({
      esn: [supplier.esn],
      hasHeadquarterBroker: [null, [Validators.required]],
      broker:  this._formBuilder.group({
        esn: [],
        contact: [null, [
          (control: UntypedFormControl) => {
            if (control.parent && control.parent.parent.get('knowsHeadquarterBrokerContact').value === true) {
              return Validators.required(control);
            }
            return null;
          }
        ]]
      }),
      isHeadquarterBrokerNotFound: [false],
      knowsHeadquarterBrokerContact: [],
    });

    const compactedSupplier = omitBy<SupplierDetail>(supplier, isNull);
    form.patchValue(compactedSupplier);
    if (compactedSupplier.broker) {
      if (compactedSupplier.broker.esn) {
        form.get('broker.esn').setValue(compactedSupplier.broker.esn);
      }
      if (compactedSupplier.broker.contact) {
        form.get('broker.contact').setValue(compactedSupplier.broker.contact);
      }
    }

    form.get('hasHeadquarterBroker').valueChanges.subscribe(() => {
      form.get('broker.esn').setValue(null);
      form.get('isHeadquarterBrokerNotFound').setValue(null);
      form.get('knowsHeadquarterBrokerContact').setValue(null);
      form.get('broker.contact').setValue(null);

      form.markAsDirty();
    });
    form.get('broker.esn').valueChanges.subscribe((val) => {
      form.get('knowsHeadquarterBrokerContact').setValue(null);
      form.get('broker.contact').setValue(null);
      if (val) {
        form.get('isHeadquarterBrokerNotFound').setValue(null, { emitEvent: false});
      }
    });
    form.get('isHeadquarterBrokerNotFound').valueChanges.subscribe((val) => {
      form.get('knowsHeadquarterBrokerContact').setValue(null);
      form.get('broker.contact').setValue(null);

      // reset broker esn and disable if true
      if (val) {
        form.get('broker.esn').setValue(null);
        form.get('broker.esn').disable();
      } else {
        form.get('broker.esn').enable();
      }
    });
    form.get('knowsHeadquarterBrokerContact').valueChanges.subscribe((val) => {
      form.get('broker.contact').setValue(null);
      form.markAsDirty();
    });
    if (supplier.isHeadquarterBrokerNotFound) {
      form.get('broker.esn').setValue(null);
      form.get('broker.esn').disable();
    }
    return form;
  }
}
