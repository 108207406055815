import { SupplierDetail } from '../models/supplier-detail';
import { SupplierStatus } from '../models/supplier-status';
import { SupplierDetailsConstants } from '../constants/supplier-details-constants';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';

export function isDraft(supplier: SupplierDetail): boolean {
  return supplier?.status === SupplierStatus.draft;
}

export function isInvitedSupplier(supplier: SupplierDetail): boolean {
  return supplier && supplier.inviteReasonType !== null && supplier.inviteReasonType !== undefined;
}

export function isInvitedDraftSupplier(supplier: SupplierDetail): boolean {
  return isDraft(supplier) && isInvitedSupplier(supplier);
}

export function isAddedDraftSupplier(supplier: SupplierDetail): boolean {
  return isDraft(supplier) && !isInvitedSupplier(supplier);
}

export function isActive(supplier: SupplierDetail): boolean {
  return supplier &&
    supplier.status &&
    supplier.status === SupplierStatus.active;
}

export function isSupplierInReview(supplier: SupplierDetail): boolean {
  return isDraft(supplier) &&
    supplier.invitedSupplierSubmittedForReviewDate !== null &&
    supplier.invitedSupplierSubmittedForReviewDate !== undefined;
}

export function getSupplierCategoryManagerFullName(supplier: SupplierDetail): string {
  if (supplier && supplier.categoryManagerCode) {
    if (supplier.categoryManagerFirstName && supplier.categoryManagerLastName) {
      return `${supplier.categoryManagerFirstName} ${supplier.categoryManagerLastName}`;
    }
    if (supplier.categoryManagerFirstName) {
      return supplier.categoryManagerFirstName;
    }
    if (supplier.categoryManagerLastName) {
      return supplier.categoryManagerLastName;
    }
  }
  return null;
}

export function getSupplierDevelopmentManagerFullName(supplier: SupplierDetail): string {
  if (supplier && supplier.supplierDevelopmentManagerCode) {
    if (supplier.supplierDevelopmentManagerFirstName && supplier.supplierDevelopmentManagerLastName) {
      return `${supplier.supplierDevelopmentManagerFirstName} ${supplier.supplierDevelopmentManagerLastName}`;
    }
    if (supplier.supplierDevelopmentManagerFirstName) {
      return supplier.supplierDevelopmentManagerFirstName;
    }
    if (supplier.supplierDevelopmentManagerLastName) {
      return supplier.supplierDevelopmentManagerLastName;
    }
  }
  return null;
}

export function getSupplierFieldName(
  validationError: { control: string; error: string; value: any },
  currentTab: SupplierDetailTabs): string {
  if (validationError.control === 'name' && currentTab === SupplierDetailTabs.general) {
    return `Legal Name`;
  }
  if (validationError.control === 'wmsLegalName' && currentTab === SupplierDetailTabs.general) {
    return `NTS Legal Name`;
  }
  if (validationError.control.includes('poTransmissions.isPONotEmpty')) {
    return `PO Transmissions`;
  }
  const field = SupplierDetailsConstants.SupplierFields.find(item => item.field === validationError.control);
  if (field) {
    return field.name;
  }
  return `Error on ${ validationError.control }`;
}

export function convertToSupplierDetails(response) {
  if (response.categoryManager) {
    response.categoryManagerCode = response.categoryManager.code;
    response.categoryManagerFirstName = response.categoryManager.firstName;
    response.categoryManagerLastName = response.categoryManager.lastName;
  }
  if (response.invitation) {
    if (response.invitation.categoryManagerContact) {
      response.categoryManagerEmail = response.invitation.categoryManagerContact.email;
    }
    response.inviteReasonType = response.invitation.reason;
    response.inviteSupplierType = response.invitation.policyProcedureType;

    response.preferredCustomerId = response.invitation.preferredCustomerId;
    response.preferredCustomerName = response.invitation.preferredCustomerName;
    response.preferredCustomerComments = response.invitation.comments;

    if (response.invitation.requester) {
      response.requesterType = response.invitation.requester.type;
      response.retailerMasterAccountNumber = response.invitation.requester.retailerMasterAccountNumber;

      response.preferredCustomerId = response.invitation.requester.preferredCustomerId;
      response.preferredCustomerName = response.invitation.requester.preferredCustomerName;
      response.preferredCustomerComments = response.invitation.requester.comments;
    }
  }
  if (response.supplierDevelopmentManager) {
    response.supplierDevelopmentManagerCode = response.supplierDevelopmentManager.code;
    response.supplierDevelopmentManagerFirstName = response.supplierDevelopmentManager.firstName;
    response.supplierDevelopmentManagerLastName = response.supplierDevelopmentManager.lastName;
  }
  return response;
}
