export enum HttpStatus {
    BadRequest = 400,
    ServerError = 500,
}

export enum UnitOfMeasurement {
    Display = 'Display (DS)',
    Pallet = 'Pallet (PL)',
    Capsule = 'Capsule (CP)'
}

export enum DocumentAction {
    Download = 'Download',
    Delete = 'Delete'
}

export enum ScoreCard {
    Good = 'GOOD',
    Better = 'BETTER',
    Best = 'BEST',
}

export enum NewProductWorkflowStatus {
   KeHeReviewCM = 'KeHE Review - CM',
   KeHeReviewEDS = 'KeHE Review - EDS',
   ProductUploadEDS = 'Product Upload - EDS',
   SupplierReview = 'Supplier Review',
   PendingAction = 'Pending Action',
   PendingActivation = 'Pending Activation',
   Activated = 'Activated',
   Draft = 'Draft',
   CutWrapReview = 'Cut & Wrap Review'
}

export enum ProductActions {
    RemovePriority = 'Remove Priority',
    ReviewRequestedBrand = 'Review Requested Brand',
    SetPriority = 'Set Priority',
    DownloadPdf = 'Download PDF',
    RefreshfromIXOne = 'Refresh from IXOne'
}

export enum ActiveProductActions {
    ScheduleChanges = 'Schedule Changes',
    RefreshfromIXOne = 'Refresh from IXOne'
}

export enum DcDetailActions {
    StartActivation = 'Start Activation',
    SetToSeasonal = 'Set to Seasonal',
    SetToActive = 'Set to Active',
    SubstituteProduct = 'Substitute Product',
    RemoveSubstitute = 'Remove Substitute',
}

export enum NameValueType {
    ProductType = 'ProductType',
    ProductFlavor = 'Flavor',
}

export enum AlertMessage {
    GenericError = 'An error has occurred. Please try again.',
    DuplicateFileUploadedError = 'Document with this name already exists. To assign this document please use the existing documents tab.',
    EnterRequiredFieldsAlert = 'You have fields below that need your attention.'
}

export enum TRAIT {
    BioEngineered = 'BE',
    CBD_Cannabidiol = 'CBDX',
    Cool = 'COOL',
    DoNotFreeze = 'DNF',
    DoNotMelt = 'DNM',
    Histamine = 'HIST',
    PROP12 = 'PP12',
    PROP65 = 'PP65',
    PFAS = 'PFAS',
}

export enum ScheduledChangeTabs {
    completed = 'Completed',
    pending = 'Pending'
}

export enum RequestedBy {
    Retailer = 'Retailer'
}
export enum ScheduledChangeTypes {
    Selling = 'Selling Effective Date',
    DcSupplierUpdate = 'Dc Supplier Update',
    PurchaseOrder = 'PO First Order Date',
    UosPack = 'Unit of Sale Pack Change',
    LevelCodes = 'Level Codes Update',
    PackagingDimensions = 'Packaging Dimensions',
    MassUploadSellingEffectiveDateChange = 'Mass Upload - Selling Effective Date Change',
    PoEffectiveDate = 'Mass Upload - PO First Order Date Change'
}

export enum CutAndWrapType {
    BuyProduct = 'BUY',
    MakeProduct = 'MAKE',
    SupplyProduct = 'SPLY'
}

export enum DPIDcs {
    DC45 = 'Ontario (45)',
    DC46 = 'Tualatin (46)'
}

export enum ProductStatus {
    Active = 'active',
    New = 'new',
    PendingDiscontinuation = 'pending discontinuation',
    Seasonal  = 'seasonal',
    ActiveSeasonal = 'active - seasonal',
    PendingCreation = 'pending creation',
    NewSeasonal = 'new - seasonal',
    Draft = 'draft',
    KeHEReviewCM = 'kehe review - cm',
    KeHEReviewEDS  = 'kehe review - eds',
    SupplierReview = 'supplier review',
    PendingActivation = 'pending activation',
    ProductUploadEDS = 'product upload - eds'
  }

  export enum CutWrapDCStatus {
    InActive = 'Inactive',
    ManufactureDiscNoInventory = 'Manuf. Disc. - No Inventory',
    KeheDiscNoInventory = 'KeHE Disc. - No Inventory',
    ActiveN = 'Active',
    NewNA = 'New',
    PendingDiscNC = 'Pending Disc.',
    ActiveSeasonalS = 'Active - Seasonal',
    NewSeasonalSA = 'New - Seasonal',
    PendingDiscSeasonalSC = 'Pending Disc. - Seasonal',
    ActiveShortShippedT = 'Active - Short Shipped',
    NewShortShippedTA = 'New - Short Shipped',
    PendingDiscShortShippedTC = 'Pending Disc. - Short Shipped',
    ManufactureOOSEveryDayNV = 'Manuf. OOS - Every Day',
    ManufactureOOSOtherSV = 'Manuf. OOS - Other',
    StagedForPricing = 'Staged For Pricing',
    PendingActivation = 'Pending Activation',
    RequiresPricing = 'Requires Pricing',
    PricingComplete = 'Pricing Complete',
    Complete = 'Complete'
}

export const InactiveCutWrapDCStatus: string[] = [
    CutWrapDCStatus.InActive,
    CutWrapDCStatus.ManufactureDiscNoInventory,
    CutWrapDCStatus.KeheDiscNoInventory,
    CutWrapDCStatus.ManufactureOOSEveryDayNV,
    CutWrapDCStatus.ManufactureOOSOtherSV
];
