import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentOverlayRef } from './components/overlay-ref/component-overlay-ref';
import { environment } from '../environments/environment';
import { ModalWorkflowService } from './services/modal-workflow.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { UserNotificationCard, KeheManageProgressItem, ExternalTopBarEventTypes } from '@kehe/phoenix-top-bar';
import { UserNotificationsFacade } from './modules/user-notifications/store/user-notifications.facade';
import { FeatureFlags } from './feature-flag/index';
import { skipWhile, switchMap, takeUntil } from 'rxjs/operators';
import { Constants } from './constants/constants';
import { PermissionsService } from '@app/services/permissions.service';
import { Destroyable } from './abstract/destroyable';
import { UploadFileModalComponent } from './components/upload-file-modal/upload-file-modal.component';
import { Environment } from '../environments/environment-enum';
import { datadogRum } from '@datadog/browser-rum';
import { BreakpointService } from '@kehe/phoenix-utils';
import { TopNavProfileOptionName } from './enum/top-nav-profile-option-name.enum';
import { TopBarExternalEventsService } from './services/top-bar-external-events.service';
import { selectConfigurableFeatureFlagIfOn } from './shared/state/feature-flag/feature-flag.selectors';
import * as AppActions from './app.actions';
import * as AppSelectors from './app.selectors';
import { PhoenixManageProgressPanelFacadeService } from '@kehe/phoenix-top-bar';
import { selectIsExportHierarchiesOpen } from './modules/utilities/hierarchies/export-hierarchies/store/selectors/export-hierarchies-modal.selectors';

declare let ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Destroyable implements OnInit {
  // create local version of Logout, so that we can hold the event
  profileOptions$ = this._store.select(AppSelectors.selectTopNavProfileOptions);
  public userFirstName$: Observable<string>;

  public userActions: string[];
  public userEmail: string;
  // needed for toast
  @ViewChild('mainContainer', { read: ViewContainerRef, static: true }) _vcr;

  // modal workflow related props
  initialModalSubscription: Subscription;
  orderInitialModalOverlayRef: ComponentOverlayRef;
  uploadFileModalSubscription: Subscription;
  uploadFileModalOverlayRef: ComponentOverlayRef;

  userNotifications$: Observable<UserNotificationCard[]>;
  userNotificationCount$: Observable<number>;
  isUserNotificationsEnabled$: Observable<boolean>;

  displayEnterpriseOutageBanner$ = this._store.select(selectConfigurableFeatureFlagIfOn(FeatureFlags.EnterpriseOutageBanner.key));
  outageAlertClosed = false;

  isSmallOrBelowView$ = this._breakpointService.isSmallAndBelow$;

  isExportHierarchiesModalOpen$ = this._store.select(selectIsExportHierarchiesOpen);

  // Manage Progress
  manageProgressItems: KeheManageProgressItem[] = [];
  topBarExternalEventsSubject: Subject<ExternalTopBarEventTypes> = new Subject<ExternalTopBarEventTypes>();

  constructor(
    private modalWorkFlowService: ModalWorkflowService,
    public router: Router,
    private _store: Store,
    private userNotificationsFacade: UserNotificationsFacade,
    private permissionsService: PermissionsService,
    private _breakpointService: BreakpointService,
    private _topBarExternalEventsService: TopBarExternalEventsService,
    private _manageProgressFacade: PhoenixManageProgressPanelFacadeService
  ) {
    super();
    // set google analytics
    if (environment.ga) {
      // run one time to setup the google analytics property id
      ga('create', environment.ga, 'auto');

      // check each route change and send google tracking event
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      });
    }

    this._store.select(AuthenticationSelectors.selectUserEmail).subscribe((userEmail) => {
      if (userEmail) {
        ga('set', 'dimension1', userEmail);
        ga('send', 'pageview');

        this.setDataDogUser(userEmail);

        this._manageProgressFacade.setUserEmail(userEmail);
      }
    });
    // modal workflow subscribers
    this.uploadFileModalSubscription =
      this.modalWorkFlowService.triggerUploadFileModal$.subscribe((val) => {
        this.launchUploadFileModal();
      });

      this._manageProgressFacade.setManageProgressApi(
        environment.manageProgressApi
      );
    }

  ngOnInit() {
    this.setupDatadog();
    this.isUserNotificationsEnabled$ = this._store.select(
      selectIsFeatureFlagOn(FeatureFlags.UserNotifications.key)
    );
    this.userNotifications$ = this.isUserNotificationsEnabled$.pipe(
      skipWhile((isEnabled) => !isEnabled),
      switchMap(() =>
        this.userNotificationsFacade.getLoadedUserNotificationCards()
      )
    );
    this.userNotificationCount$ = this.isUserNotificationsEnabled$.pipe(
      skipWhile((isEnabled) => !isEnabled),
      switchMap(() => this.userNotificationsFacade.getUserNotificationCount())
    );

    this.userFirstName$ = this._store.select(AuthenticationSelectors.selectUserFirstName);

    this._store
      .pipe(select(AuthenticationSelectors.selectUserActions), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.userActions = val;
        if (this.userActions) {
          this.checkSiteAccess();
        }
      });

    this._topBarExternalEventsService.eventSubject.subscribe(e => {
      this.topBarExternalEventsSubject.next(e);
    })
  }

  setupDatadog(): void {
    switch (environment.current) {
      case Environment.UAT:
        datadogRum.init({
          applicationId: 'fe603c51-84b6-4e3a-a05c-3ddcab3fda34',
          clientToken: 'pub6988329536ee9cb10df2e843e59a7341',
          site: 'datadoghq.com',
          service: 'connect-enterprise',
          env: 'uat',
          sampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          workerUrl: 'datadog_scripts/worker.js',
        });

        datadogRum.startSessionReplayRecording();
        break;

      case Environment.PROD:
        datadogRum.init({
          applicationId: 'fe603c51-84b6-4e3a-a05c-3ddcab3fda34',
          clientToken: 'pub6988329536ee9cb10df2e843e59a7341',
          site: 'datadoghq.com',
          service: 'connect-enterprise',
          env: 'prod',
          sampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          workerUrl: 'datadog_scripts/worker.js',
        });

        datadogRum.startSessionReplayRecording();
        break;

      default:
    }
  }

  setDataDogUser(userEmail) {
    datadogRum.setUser({
      email: userEmail.toLowerCase(),
    });
  }

  userSectionClick(profileOption: { name: TopNavProfileOptionName }) {
    switch (profileOption.name) {
      case TopNavProfileOptionName.Logout:
        this._store.dispatch(AppActions.logOut());
        break;
      case TopNavProfileOptionName.WorkQueue:
        this.router.navigateByUrl('/workqueue');
        break;
      case TopNavProfileOptionName.ManageProgress:
        this._manageProgressFacade.openManageProgressPanel();
        break;
    }
  }

  openHelp() {
    this.router.navigate(['help']);
  }

  // modal workflow:
  showUploadFileModal() {
    this.uploadFileModalOverlayRef =
      this.modalWorkFlowService.openSmartComponent(UploadFileModalComponent);
  }

  dismissInitialModal() {
    this.orderInitialModalOverlayRef.close();
  }

  launchUploadFileModal() {
    // close any other modal
    this.dismissInitialModal();
    // new upload file modal
    this.showUploadFileModal();
  }

  onNotificationIconClick(shouldDisplayNotificationPanel: boolean): void {
    if (shouldDisplayNotificationPanel) {
      this.userNotificationsFacade.loadUserNotifications();
    }
  }

  onNotificationRedirect(notificationUrl: string): void {
    window.open(notificationUrl, '_blank');
  }

  onClearNotifications(notificationIds: number[]): void {
    this.userNotificationsFacade.clearUserNotifications(notificationIds);
  }

  get isUserAgreementEnabled$(): Observable<boolean> {
    return this._store.select(selectIsFeatureFlagOn(FeatureFlags.UserAgreement.key));
  }

  // ngrx todo: this should be in an effect and enforced on login
  private checkSiteAccess(): void {
    if (
      !this.permissionsService.userHasAction(
        Constants.UserActions.EnterpriseSiteAccess
      )
    ) {
      this._store.dispatch(AppActions.logOut());
    }
  }

  handleCloseOutageAlert() {
    this.outageAlertClosed = true;
  }
}
