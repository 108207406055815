import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '../../../constants/constants';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { map } from 'rxjs/operators';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class CustomPromotionsGuard  {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.CustomPromotionsRead)),
      this.store.select(
        selectIsFeatureFlagOn(FeatureFlags.ManageCustomPromotions.key)
      ),
    ]).pipe(
      map(([hasCustomPromotionsRead, manageCustomPromotionsFF]) => {
        return hasCustomPromotionsRead && manageCustomPromotionsFF;
      })
    );
  }
}
