import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralTabComponent } from './components/general-tab/general-tab.component';
import { GeneralViewComponent } from './components/general-view/general-view.component';
import { GeneralFormComponent } from './components/general-form/general-form.component';
import { UnsavedChangesModalComponent } from './components/unsaved-changes-modal/unsaved-changes-modal.component';
import { SupplierDetailRoutingModule } from './supplier-detail-routing.module';
import { TermsTabComponent } from './components/terms-tab/terms-tab.component';
import { TermsViewComponent } from './components/terms-view/terms-view.component';
import { TermsFormComponent } from './components/terms-form/terms-form.component';
import { BrokerDetailService } from './../broker-detail/services/broker-detail.service';
import { PhoenixAddressModule } from '@kehe/phoenix-address';
import { HttpClientModule } from '@angular/common/http';
import { CanDeactivateGuardService } from '../../services/can-deactivate-guard.service';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { BrokerService } from '../broker-list/services/broker.service';
import { AddBrokerModalComponent } from './components/add-broker-modal/add-broker-modal.component';
import { DeleteBrokerModalComponent } from './components/delete-broker-modal/delete-broker-modal.component';
import { SupplierCodeValueService } from './services/supplier-code-value.service';
import { SupplierTermsDropdownsService } from './services/supplier-terms-dropdowns.service';

import { PhoenixPhoneNumberModule } from '@kehe/phoenix-phone-number';
import { PhoenixContactModule } from '@kehe/phoenix-contact';
import { BuyerService } from './services/buyer.service';
import {
  BodyModule,
  FilterMenuModule,
  GridModule,
  HeaderModule,
  SharedModule,
} from '@progress/kendo-angular-grid';
import { NotesModalComponent } from './components/notes-modal/notes-modal.component';
import { HistoryTabComponent } from './components/history-tab/history-tab.component';
import { PhoenixAuditHistoryModule } from '@kehe/phoenix-audit-history';
import { HistorySupplierFilterPanelComponent } from './components/history-supplier-filter-panel/history-supplier-filter-panel.component';
import {
  ComboBoxModule,
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { ProductGroupsTabComponent } from './components/product-groups-tab/product-groups-tab.component';
import { ProductGroupsListComponent } from './components/product-groups-list/product-groups-list.component';
import { ProductGroupsViewComponent } from './components/product-groups-view/product-groups-view.component';
import { ProductGroupsFilterSlidePanelComponent } from './components/product-groups-filter-slide-panel/product-groups-filter-slide-panel.component';
import { PhoenixTabsModule } from '@kehe/phoenix-tabs';
import { PhoenixChipsModule } from '@kehe/phoenix-chips';

import {
  CheckBoxModule,
  InputsModule,
  TextAreaModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ProductGroupsFormComponent } from './components/product-groups-form/product-groups-form.component';
import { ProductGroupsDcFormComponent } from './components/product-groups-dc-form/product-groups-dc-form.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AddProductGroupFormComponent } from './components/add-product-group-form/add-product-group-form.component';
import {
  BreadCrumbModule,
  NavigationModule,
} from '@progress/kendo-angular-navigation';
import { AddDcProductGroupFormComponent } from './components/add-dc-product-group-form/add-dc-product-group-form.component';
import { UploadModule, UploadsModule } from '@progress/kendo-angular-upload';
import { ProductGroupsDcTabComponent } from './components/product-groups-dc-tab/product-groups-dc-tab.component';
import { AddProductGroupModalComponent } from './components/add-product-group-modal/add-product-group-modal.component';
import { SupplierDetailPageComponent } from './pages/supplier-detail-page/supplier-detail-page.component';
import { SupplierDetailService } from './services/supplier-detail.service';
import { SupplierStatusChangeModalComponent } from './components/supplier-status-change-modal/supplier-status-change-modal.component';
import {
  ButtonGroupModule,
  ButtonsModule,
} from '@progress/kendo-angular-buttons';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BrandsTabComponent } from './components/brands-tab/brands-tab.component';
import { ContactsTabComponent } from './components/contacts-tab/contacts-tab.component';
import { SubmitSupplierModalComponent } from './components/submit-supplier-modal/submit-supplier-modal.component';
import { DocumentsTabComponent } from './components/documents-tab/documents-tab.component';
import {
  PhoenixButtonModule,
  PhoenixMenuButtonModule,
} from '@kehe/phoenix-button';
import { PhoenixUploadModule } from '@kehe/phoenix-upload';
import { GeneralTabStatusUpdateModalComponent } from './components/general-tab-status-update-modal/general-tab-status-update-modal.component';
import { PhoenixTooltipModule } from '@kehe/phoenix-tooltip';
import { PhoenixNavigationPanelModule } from '@kehe/phoenix-navigation-panel';
import {
  PhoenixAlertsModule,
  PhoenixToastModule,
} from '@kehe/phoenix-notifications';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { PhoenixSlidePanelModule } from '@kehe/phoenix-slide-panel';
import { PhoenixSearchModule } from '@kehe/phoenix-search';
import { BreadCrumbsComponent } from '@app/breadcrumbs/breadcrumbs.component';
import { SupplierContactsViewComponent } from './components/supplier-contacts-view/supplier-contacts-view.component';
import { SupplierContactsModule } from '../supplier-contacts/supplier-contacts.module';
import { NewContractModalComponent } from './components/new-contract-modal/new-contract-modal.component';
import { LegalSignaturesTabComponent } from './components/legal-signatures-tab/legal-signatures-tab.component';
import { DocusignEmailModalComponent } from './components/docusign-email-modal/docusign-email-modal.component';
import { PurchasingInfoTabComponent } from './components/purchasing-info-tab/purchasing-info-tab.component';
import { DcpgFormService } from './services/dcpg-form.service';
import { SupplierDocumentsModule } from '../supplier-documents/supplier-documents.module';
import { SupplierUsersModule } from '../supplier-users/supplier-users.module';
import { BrokerViewComponent } from './components/broker-view/broker-view.component';
import { BrokerFormComponent } from './components/broker-form/broker-form.component';
import { DeleteSupplierModalComponent } from './components/delete-supplier-modal/delete-supplier-modal.component';
import { LocationsTabComponent } from './components/locations-tab/locations-tab.component';
import { SupplierLocationsModule } from '../supplier-locations/supplier-locations.module';
import { ProductGroupsModule } from '../product-groups/product-groups.module';
import { PhoenixManageDetailPageLayoutsModule } from '@kehe/phoenix-layouts';
import { SupplierComponentsModule } from '../supplier-components/supplier-components.module';
import { AddressBookFormService } from '../../services/address-book-form.service';
import { PerformanceTabComponent } from './components/performance-tab/performance-tab.component';
import { BrokerTabV2Component } from './components/broker-tab-v2/broker-tab-v2.component';
import { KeheUtilDirectivesModule } from '@kehe/phoenix-utils';
import { AddBrokerV2ModalComponent } from './components/broker-tab-v2/add-broker-v2-modal/add-broker-v2-modal.component';
import { BrokerTabV2GridComponent } from './components/broker-tab-v2/broker-tab-v2-grid/broker-tab-v2-grid.component';
import { PhoenixEmptyStateModule } from '@kehe/phoenix-empty-state';
import { ScheduleAppointmentModalComponent } from './components/schedule-appointment-modal/schedule-appointment-modal.component';
import { BrokerTabV2DeleteConfirmationModalComponent } from './components/broker-tab-v2/broker-remove-confirmation-modal/broker-tab-v2-delete-confirmation-modal.component';
import { AssociatedSupplierTabComponent } from './components/associated-supplier-tab/associated-supplier-tab.component';
import { AssociatedSuppliersTabGridComponent } from './components/associated-supplier-tab/associated-suppliers-tab-grid/associated-suppliers-tab-grid.component';
import { AssociateNewSupplierModalComponent } from './components/associated-supplier-tab/associate-new-supplier-modal/associate-new-supplier-modal.component';
import { AssociatedSuppliersTabChildrenGridComponent } from './components/associated-supplier-tab/associated-suppliers-tab-children-grid/associated-suppliers-tab-children-grid.component';
import { AssociatedSupplierDeleteConfirmationComponent } from './components/associated-supplier-tab/associated-supplier-delete-confirmation/associated-supplier-delete-confirmation.component';
import { PhoenixErrorsModule } from '@kehe/phoenix-errors';
import { DPIService } from './services/dpi.service';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { DisplayAcceptsExcludedProgramPipe } from './pipes/display-accepts-excluded-program.pipe';
import { EmptyValueBlankPipe } from './pipes/empty-value-blank.pipe';
import { DisplayYesNoPipe } from '@app/shared/pipes/display-yes-no.pipe';
import { EmptyValuePipe } from '@app/shared/pipes/empty-value.pipe';
import { PhoneNumberPipe } from '@app/shared/pipes/phoneNumber.pipe';
import { ChecklistNavigationPanelComponent } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import * as fromSupplierDetail from './store/supplier-detail.reducer';
import * as SupplierEffects from './store/effects';

@NgModule({
  declarations: [
    SupplierDetailPageComponent,
    GeneralTabComponent,
    GeneralViewComponent,
    GeneralFormComponent,
    UnsavedChangesModalComponent,
    TermsTabComponent,
    TermsViewComponent,
    TermsFormComponent,
    AddBrokerModalComponent,
    DeleteBrokerModalComponent,
    NotesModalComponent,
    HistoryTabComponent,
    HistorySupplierFilterPanelComponent,
    ProductGroupsTabComponent,
    AddProductGroupFormComponent,
    ProductGroupsListComponent,
    ProductGroupsViewComponent,
    ProductGroupsFilterSlidePanelComponent,
    ProductGroupsFormComponent,
    ProductGroupsDcTabComponent,
    AddProductGroupModalComponent,
    ProductGroupsDcFormComponent,
    AddDcProductGroupFormComponent,
    SupplierStatusChangeModalComponent,
    BrandsTabComponent,
    ContactsTabComponent,
    SubmitSupplierModalComponent,
    DocumentsTabComponent,
    GeneralTabStatusUpdateModalComponent,
    SupplierContactsViewComponent,
    NewContractModalComponent,
    LegalSignaturesTabComponent,
    DocusignEmailModalComponent,
    PurchasingInfoTabComponent,
    BrokerViewComponent,
    BrokerFormComponent,
    DeleteSupplierModalComponent,
    LocationsTabComponent,
    PerformanceTabComponent,
    BrokerTabV2Component,
    AddBrokerV2ModalComponent,
    ScheduleAppointmentModalComponent,
    BrokerTabV2GridComponent,
    BrokerTabV2DeleteConfirmationModalComponent,
    AssociatedSupplierTabComponent,
    AssociatedSuppliersTabGridComponent,
    AssociateNewSupplierModalComponent,
    AssociatedSuppliersTabChildrenGridComponent,
    AssociatedSupplierDeleteConfirmationComponent,
    AddNoteComponent,
  ],
  imports: [
    CommonModule,
    TextAreaModule,
    BreadCrumbModule,
    PhoenixSlidePanelModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    PhoenixIndicatorsModule,
    PhoenixNavigationPanelModule,
    PhoenixAlertsModule,
    PhoenixChipsModule,
    PhoenixErrorsModule,
    SupplierDetailRoutingModule,
    PhoenixModalModule,
    StoreModule.forFeature('supplierDetail', fromSupplierDetail.reducer),
    EffectsModule.forFeature([
      SupplierEffects.SupplierDetailEffects,
      SupplierEffects.SupplierGeneralTabEffects,
      SupplierEffects.SupplierHistoryTabEffects,
      SupplierEffects.SupplierTermsTabEffects,
      SupplierEffects.SupplierTabStatusEffects,
      SupplierEffects.PurchasingInfoTabEffects,
      SupplierEffects.SupplierProductGroupsTabEffects,
      SupplierEffects.SupplierDocusignEffects,
      SupplierEffects.SupplierPurchasingOptionsEffects,
      SupplierEffects.SupplierDocumentsEffects,
      SupplierEffects.SupplierContactsEffects,
      SupplierEffects.SupplierDPIEffects,
      SupplierEffects.SupplierUtilityDataEffects,
    ]),
    PhoenixAddressModule,
    HttpClientModule,
    CheckBoxModule,
    PhoenixPhoneNumberModule,
    PhoenixContactModule,
    PhoenixAuditHistoryModule,
    GridModule,
    DropDownsModule,
    PhoenixTooltipModule,
    HeaderModule,
    BodyModule,
    FilterMenuModule,
    PhoenixTabsModule,
    SharedModule,
    LabelModule,
    InputsModule,
    ComboBoxModule,
    DateInputsModule,
    NavigationModule,
    MultiSelectModule,
    UploadModule,
    UploadsModule,
    ButtonGroupModule,
    ButtonsModule,
    NgxSkeletonLoaderModule,
    PhoenixButtonModule,
    PhoenixUploadModule,
    PhoenixSearchModule,
    PhoenixToastModule,
    BreadCrumbsComponent,
    SupplierContactsModule,
    SupplierDocumentsModule,
    PhoenixMenuButtonModule,
    SupplierUsersModule,
    SupplierLocationsModule,
    ProductGroupsModule,
    PhoenixManageDetailPageLayoutsModule,
    SupplierComponentsModule,
    KeheUtilDirectivesModule,
    PhoenixEmptyStateModule,
    DisplayAcceptsExcludedProgramPipe,
    EmptyValueBlankPipe,
    DisplayYesNoPipe,
    EmptyValuePipe,
    PhoneNumberPipe,
    ChecklistNavigationPanelComponent,
  ],
  providers: [
    SupplierTermsDropdownsService,
    SupplierDetailService,
    BrokerDetailService,
    BrokerService,
    DatePipe,
    HttpClientModule,
    CanDeactivateGuardService,
    SupplierCodeValueService,
    BuyerService,
    DcpgFormService,
    AddressBookFormService,
    DPIService,
  ],
  exports: [],
})
export class SupplierDetailModule {}
