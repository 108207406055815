import { DocumentUpsertRequest } from './../models/document-upsert-request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { Payload } from '../../../models/payload';
import { environment } from '../../../../environments/environment';
import { catchError, delay, map, retry, retryWhen, take, tap } from 'rxjs/operators';
import { ProductDetail } from '../../product-detail/models/product-detail';
import { ProductHistoryFilterItem } from '../models/product-history-filter';
import { ProductHistoryFilterToHttpParams } from './product-filter-parser';
import { ProductHistoryFilter } from '../store/product-detail.state';
import { IAuditDetail } from '@kehe/phoenix-audit-history';
import { convertToEasternTime } from '@app/shared/utils/time-zone-util';
import { IScheduleChangesItem } from '@kehe/connect-common-scheduled-changes-control-lib';
import { HttpStatus } from '../models/common.enum';
import { PresignedUrlResponse } from '../models/presigned-url-response';
import { CommentDetail } from '../models/comment-detail';
import { Document } from '../models/document';
import { ProductSnapshot } from '../models/product-snapshot';
import { NameValuePayload } from '../models/name-value-payload';
import { DistributionCenter } from '../models/distribution-center';
import { CutAndWrapAssociatesMakeProductGridDataItem } from '../models/cut-and-wrap-make-product-grid-data-item';
import { CutWrapComponentCheck } from '../models/cut-wrap-component-check.interface';

@Injectable({
    providedIn: 'root'
})

export class ProductDetailService {
  private _apiUrl: string;
  private _apiEnterpriseUrl: string;
  private _apiGateWay: string;
  private _apiNewProductUrl: string;
  private _apiNamevaluePairUrl: string;
  private _apiScheduledChangesUrl: string;
  private _suppliersNameValuePairUrl: string;
  private _apiSupplierEnterpriseUrl: string;
  private _apiPricingManagemenrUrl: string;
  private _supplierUrl: string;
  private _apiIxOneUrl: string;
  private _apiCutWrapProductUrl: string;

  constructor(private httpClient: HttpClient) {
    this._apiGateWay = environment.apiGateway;
    this._apiUrl = environment.productApi;
    this._apiEnterpriseUrl = `${this._apiUrl}/enterprise`;
    this._apiNewProductUrl = `${this._apiUrl}/new-products`;
    this._apiNamevaluePairUrl = `${this._apiUrl}/namevaluepair`;
    this._supplierUrl = `${environment.supplierApi}/api`;
    this._apiSupplierEnterpriseUrl = `${environment.supplierApi}/api/enterprise`;
    this._suppliersNameValuePairUrl = `${environment.supplierApi}/enterprise/codevaluepair`;
    this._apiScheduledChangesUrl = `${this._apiEnterpriseUrl}/scheduled-changes`;
    this._apiPricingManagemenrUrl = `${environment.v1ConnectHost}/PricingManagement/services/api`;
    this._apiIxOneUrl = environment.ixoneApi;
    this._apiCutWrapProductUrl = `${this._apiUrl}/cut-wrap`;
  }

  cutWrapCopyComponentCheck(makeUpc: string, dcCopy: string, newDC: string) {
    const params = (new HttpParams())
      .set('makeUpc', makeUpc)
      .set('dcCopy', dcCopy)
      .set('newDc', newDC);
    return this.httpClient.get<CutWrapComponentCheck>(`${this._apiCutWrapProductUrl}/copy-component-check`, {
      params
    });
  }

  getProductDetail(upc: string): Observable<ProductDetail> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/${upc}`)
      .pipe(retry(2));
  }

  getNewProductDetail(upc: string, skipRetry: boolean = false): Observable<ProductDetail> {
    let request: Observable<ProductDetail> = this.httpClient.get<any>(`${this._apiNewProductUrl}/${upc}`);
    if (!skipRetry) {
      request = request.pipe(
        retryWhen(errors =>
          errors.pipe(
            delay(1000),
            tap(error => {
              if (error.status === HttpStatus.BadRequest) {
                throw error;
              }
            }),
            take(2)
          ),
        ),
        retry(4)
      );
    }
    return request;
  }

  getProductInventory(upc: string): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiUrl}/${upc}/inventory`)
      .pipe(retry(2));
  }

  getProductTraits(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/traits`)
      .pipe(retry(2));
  }

  getDistributionCenterList(): Observable<Payload<DistributionCenter[]>> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/distributioncenterlist`)
      .pipe(retry(2));
  }

  getProductUomNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/uoms`)
      .pipe(retry(2));
  }

  getDivisionNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/divisions`)
      .pipe(retry(2));
  }
  getPrintCatalogCodeValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/printcatalogcodes`)
      .pipe(retry(2));
  }

  getProductContainerNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/containers`)
      .pipe(retry(2));
  }

  getProductPickTypeNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/picktypes`)
      .pipe(retry(2));
  }

  getDcStatusNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/dc-statuses`)
      .pipe(retry(2));
  }
  getProductShippingTemperatureNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/temperatures`)
      .pipe(retry(2));
  }

  getProductRetailTemperatureNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/temperatures`)
      .pipe(retry(2));
  }

  getProductSubstitutionCodeNameValuePairs(): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/substitutioncode`)
      .pipe(retry(2));
  }

  getProductDcDetailsSubstitutionUpc(baseUpc: string, dcNumber: string, querySubstitutionUpc: string): Observable<Array<object>> {
    let string = '';
    if (querySubstitutionUpc) {
      string += '/' + querySubstitutionUpc;
    }
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/${baseUpc}/${dcNumber}/substitutionupc${string}`)
      .pipe(retry(2));
  }

  getProductCategoryManagerDetails(categoryManagerCode?: string): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/categories/managers/${categoryManagerCode}`)
    .pipe(retry(2));
  }

  getProductCategoryManagerValuePairs(categoryManager?: string): Observable<Payload<any>> {
    let httpParams = new HttpParams();
    if (categoryManager) {
      httpParams = new HttpParams().set('query', categoryManager);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/categorymanagers`, { params: httpParams })
      .pipe(retry(2));
  }

  getProductBrandNameValuePairs(brand?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (brand) {
      httpParams = new HttpParams().set('query', brand);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/brands`, { params: httpParams })
      .pipe(retry(2));
  }
  getProductBrandsByEsn(brand?: string, esn?: string): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiSupplierEnterpriseUrl}/${esn}/brands/${brand}`)
      .pipe(retry(2));
  }
  getProductBrands(brand?: string): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiSupplierEnterpriseUrl}/brands/${brand}`)
      .pipe(retry(2));
  }

  public getTypeNameValuePairs(type?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (type) {
      httpParams = new HttpParams().set('query', type);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/producttypes`, { params: httpParams })
      .pipe(retry(2));
  }

  public getProductFlavorNameValuePairs(productFlavor?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (productFlavor) {
      httpParams = new HttpParams().set('query', productFlavor);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/flavors`, { params: httpParams })
      .pipe(retry(2));
  }

  getProductPriceGroupValuePairs(familyGroup?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (familyGroup) {
      httpParams = new HttpParams().set('query', familyGroup);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/pricegroups`, { params: httpParams })
      .pipe(retry(2));
  }

  public getProductBottleDepositNameValuePairs(type: string) {
    const httpParams = new HttpParams().set('type', type);
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/bottle-deposit-codes`, { params: httpParams })
      .pipe(retry(2));
  }

  public getCountryOfOriginNameValuePairs(country?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (country) {
      httpParams = new HttpParams().set('query', country);
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/countries`, { params: httpParams })
      .pipe(retry(2));
  }

  public getProcessedNameValuePairs(): Observable<Payload<any>> {
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/processed`).pipe(retry(2));
  }

  public getProductClassNameValuePairs(divisionCode?: string, productClass?: string): Observable<Array<object>> {
    let string = '';
    if (divisionCode) {
      string += '?divisionCode=' + divisionCode;
    }
    if (productClass) {
      string += '&productClass=' + productClass;
    }
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/product-class${string}`)
      .pipe(retry(2));
  }

  activateDcProduct(params): Observable<any> {
    const activationParams = {...params};
    activationParams.hasPricingInformation = (params.dcProduct.activationStatus === 'Pricing Complete') ? true : false;
    return this.httpClient
      .post(`${this._apiEnterpriseUrl}/${params.upc}/activate/${params.dcProduct.distributionCenter.number}`
      , activationParams, {responseType: 'json'});
  }

  activateProduct(upc): Observable<any> {
    return this.httpClient
      .post(`${this._apiNewProductUrl}/${upc}/activate`
      , null, {responseType: 'text'});
  }

  approveNewProduct(upc): Observable<any> {
    const headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.httpClient
      .post(`${this._apiNewProductUrl}/${upc}/approve`, { headers });
  }


  approveNewCutWrapReviewProduct(upc): Observable<any> {
    const headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.httpClient
      .post(`${this._apiNewProductUrl}/${upc}/cut-wrap-approve`, { headers });
  }

  saveReviseStatus(request, upc): Observable<any> {
    return this.httpClient
      .post(`${this._apiNewProductUrl}/${upc}/revise`,
      request, {responseType: 'json'});
  }

  upsertNameValuePair(request: NameValuePayload): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/upsertnvp`,
      request, {responseType: 'json'});
  }

  updateProductDetail(params): Observable<any> {
    return this.httpClient.patch(`${this._apiEnterpriseUrl}/update/${params.upc}`, params, {responseType: 'json'});
  }

  updateCutWrapProduct(params): Observable<any> {
    return this.httpClient.post(`${this._apiCutWrapProductUrl}/upsert`, params, {responseType: 'json'});
  }

  getUpcEanStatus(upc, ean): Observable<any[]> {
    if (upc && !ean) {
      const upcStatus = this.httpClient.get(`${this._apiNewProductUrl}/${upc}/status`).pipe(retry(2));
      return forkJoin([upcStatus]);
    }
    if (ean && !upc) {
      const eanStatus = this.httpClient.get(`${this._apiNewProductUrl}/ean/${ean}/status`).pipe(retry(2));
      return forkJoin([eanStatus]);
    }
    if (ean && upc) {
      const upcStatus = this.httpClient.get(`${this._apiNewProductUrl}/${upc}/status`).pipe(retry(2)),
      eanStatus = this.httpClient.get(`${this._apiNewProductUrl}/ean/${ean}/status`).pipe(retry(2));
      return forkJoin([upcStatus, eanStatus]);
    }
  }

  createScheduleChanges(params, dateToCompare): Observable<any> {
    const paramsWithDateToCompare = {
      ...params,
      dateToCompare
    };
    return this.httpClient.post(`${this._apiScheduledChangesUrl}/create`, paramsWithDateToCompare, {responseType: 'json'});
  }

  updateScheduleChanges(request: any): Observable<any> {
    return this.httpClient.put(`${this._apiScheduledChangesUrl}/${request.id}`, request, {responseType: 'json'});
  }

  public getProductFilterCheckItemPayloadWithParams(filterPath: string, param?: string) {
    let httpParams = new HttpParams();
    if (param) {
      httpParams = new HttpParams().set('query', param);
    }

    return this.httpClient
      .get<Payload<Array<object>>>(this._apiEnterpriseUrl + filterPath, { params: httpParams })
      .pipe(retry(2));
  }

  public getDepartmentProductMappings(levelCode?: string): Observable<Array<Object>> {
    let httpParams = new HttpParams();
    if (levelCode) {
      httpParams = new HttpParams().set('query', levelCode);
    }
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/categories/mappings`, {params: httpParams })
      .pipe(retry(2));
  }

  public getDepartmentCategories(levelCode?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (levelCode) {
      httpParams = new HttpParams().set('query', levelCode);
    }
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/categories/departments`, { params: httpParams })
      .pipe(retry(2));
  }

  public getProductCategories(levelCode?: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (levelCode) {
      httpParams = new HttpParams().set('query', levelCode);
    }
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/categories/products`, { params: httpParams })
      .pipe(retry(2));
  }

  public getProducts(search?: string, excludeShippers?: boolean): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (search) {
      httpParams = httpParams.set('search', search);
    }
    if (!!excludeShippers) {
      httpParams = httpParams.append('excludeshippers', excludeShippers.toString());
    }
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}`, { params: httpParams })
      .pipe(retry(2));
  }

  public getDraftAndActiveProducts(search?: string, excludeShippers?: boolean, isNewProducts?: boolean, esn?: string, currentupc?: string, excludeRandomWeight?: boolean): Observable<Array<object>> {
    let httpParams = new HttpParams();
    let products: Observable<Array<object>>;
    let newProducts: Observable<Array<object>>;
    if (search) {
      httpParams = httpParams.set('search', search);
    }
    if (currentupc) {
      httpParams = httpParams.set('currentupc', currentupc);
    }

    if (excludeRandomWeight) {
      httpParams = httpParams.set('excludeRandomWeight', excludeRandomWeight);
    }

    if (esn) {
      httpParams = httpParams.append('esn', esn);
    }
    if (!!excludeShippers) {
      httpParams = httpParams.append('excludeshippers', excludeShippers.toString());
    }
    products = this.httpClient.get<any>(`${this._apiEnterpriseUrl}`, { params: httpParams })
    .pipe(retry(2));
    if (isNewProducts) {
      newProducts = this.httpClient.get<any>(`${this._apiNewProductUrl}`, { params: httpParams })
      .pipe(map(p => {
        return {...p, data: p.data.map(d => {
          return {
            ...d,
            isNewProduct: true
          }
        })}
      }))
      .pipe(retry(2));
    } else {
      return products;
    }
    return forkJoin([products, newProducts]);
  }

  public getHistoryFilterEvents(upc: string): Observable<Payload<Array<ProductHistoryFilterItem>>> {
    return this.httpClient
    .get<Payload<Array<ProductHistoryFilterItem>>>(`${this._apiEnterpriseUrl}/${upc}/auditfilters/Events`)
    .pipe(retry(2));
  }

  public getHistoryFilterFields(upc: string, search?: string): Observable<Payload<Array<ProductHistoryFilterItem>>> {
    let httpParams = new HttpParams();
    if (search) {
      httpParams = new HttpParams().set('search', search);
    }
    return this.httpClient
    .get<Payload<Array<ProductHistoryFilterItem>>>(`${this._apiEnterpriseUrl}/${upc}/auditfilters/Fields`, { params: httpParams })
    .pipe(retry(2));
  }

  public getHistoryFilterEditedBy(upc: string, search?: string): Observable<Payload<Array<ProductHistoryFilterItem>>> {
    let httpParams = new HttpParams();
    if (search) {
      httpParams = new HttpParams().set('search', search);
    }
    return this.httpClient
      .get<Payload<Array<ProductHistoryFilterItem>>>(`${this._apiEnterpriseUrl}/${upc}/auditfilters/CreatedBy`, { params: httpParams })
      .pipe(retry(2));
  }

  public getScheduledChangesCount(upc: string): Observable<Payload<number>> {
    return this.httpClient
      .get<Payload<number>>(`${this._apiScheduledChangesUrl}/${upc}/count`)
      .pipe(retry(2));
  }

  public getAuditHistory(upc: string, filter: ProductHistoryFilter): Observable<Payload<Array<IAuditDetail>>> {
    const params = ProductHistoryFilterToHttpParams(filter);
    return this.httpClient.get<Payload<Array<IAuditDetail>>>(
      `${this._apiUrl}/${upc}/audit`, { params }).pipe(retry(2), map((data: Payload<Array<IAuditDetail>>) => {
      data.data.forEach((row) => {
        row.created = convertToEasternTime(row.created);

        row.changes.forEach((change) => {
          if (typeof change.originalValue === 'object' && change && change.originalValue) {
            change.originalValue = change.originalValue['CodeValue'];
          }
          if (typeof change.newValue === 'object' && change && change.newValue) {
            change.newValue = change.newValue['CodeValue'];
          }
        });
      });
      return data;
    }));
  }

  public getScheduledChanges(upc: string, query: string): Observable<Payload<Array<IScheduleChangesItem>>> {
    let params = new HttpParams();
    if (query) {
      params = new HttpParams().set('query', query);
    }
    return this.httpClient.get<Payload<Array<IScheduleChangesItem>>>(
      `${this._apiScheduledChangesUrl}/${upc}`, { params }).pipe(retry(2), map((data) => {
      return data;
    }));
  }

  public deleteScheduledChanges(upc: string, id: number): Observable<Payload<IScheduleChangesItem>> {
    return this.httpClient.delete<Payload<IScheduleChangesItem>>(
      `${this._apiScheduledChangesUrl}/${upc}/${id}`).pipe(retry(2), map((data) => {
      return data;
    }));
  }

  public getBottleDepositCodesFullList(): Observable<Payload<number>> {
    return this.httpClient
      .get<Payload<number>>(`${this._apiEnterpriseUrl}/bottledepositcodes?type=bd`)
      .pipe(retry(2));
  }

  getBeverageNameValuePairs(param: string): Observable<Array<object>> {
    const httpParams = new HttpParams().set('group', param);
    return this.httpClient.get<any>(`${this._apiNamevaluePairUrl}/traits`, { params: httpParams })
      .pipe(retry(2));
  }

  getSuppliersNameValuePairs(supplier: string): Observable<Array<object>> {
    let httpParams = new HttpParams();
    if (supplier) {
      httpParams = new HttpParams().set('query', supplier);
    }
    return this.httpClient.get<any>(`${this._suppliersNameValuePairUrl}/active-enterprise-suppliers`, { params: httpParams })
      .pipe(retry(2));
  }

  getDiscontinuedSuppliersNameValuePairs(supplier: string): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._supplierUrl}/product-suppliers/${supplier}`)
      .pipe(retry(2));
  }

  getSupplierProductGroupsNameValuePairs(dc: any, supplierNumber: any): Observable<Array<object>> {
    return this.httpClient.get<any>(`${environment.supplierEnterprise}/${supplierNumber}/product-groups/${dc}`)
      .pipe(catchError(() => of({data: []})), retry(2));
  }
  /// Returns the value with delivery method and zipcode, used on the pricing tab fordraft products.
  getDraftProductGroupsNameValuePairs(dc: any, supplierNumber: any): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/suppliers/${supplierNumber}/product-groups/${dc}/draft`)
      .pipe(catchError(() => of({data: []})), retry(2));
  }

  getProductGroupsNameValuePairs(dc: any, supplierNumber: any): Observable<Array<object>> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/suppliers/${supplierNumber}/product-groups/${dc}`)
      .pipe(catchError(() => of({data: []})), retry(2));
  }

  getProductPricingInfoForDcs(upc: string, dcZones: number[]): Observable<any> {
     dcZones = (dcZones[0] === undefined) ? [0] : dcZones;
     const params = {
       upc: upc,
       zones: dcZones
     };
      return this.httpClient.post(`${environment.apiGateway}/pricing/{upc}/current`, params, {responseType: 'json'});
  }

  public updateNewProductDetail(params): Observable<any> {
    return this.httpClient.patch(this._apiNewProductUrl, params, {responseType: 'json'});
  }

  public getDcShipperInformation(shipperUpc: string): Observable<any> {
    return this.httpClient.get<any>(`${this._apiNewProductUrl}/dcshipperinformation/${shipperUpc}`)
    .pipe(retry(2));
  }
  public getDocumentsList(upc: string): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/documents/${upc}`)
    .pipe(retry(2));
  }

  public getPreSignedDocumentURL(document: any): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/documents/${document.esn}/${document.uniqueId}`)
    .pipe(retry(2));
  }

  public getDocumentTypes(): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/documents/documenttype`)
      .pipe(retry(2));
  }

  public getExistingDocumentTypes(esn: string): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/documents/${esn}/documenttypes`)
      .pipe(retry(2));
  }

  public getExistingDocuments(esn: string, documentTypeCode: string): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/documents/${esn}/search-uploaded/${documentTypeCode}`)
      .pipe(retry(2));
  }

  public getUploadUrl(esn: string, originalFilename: string): Observable<PresignedUrlResponse> {
    const encodedFilename = encodeURIComponent(originalFilename);
    return this.httpClient
      .get<PresignedUrlResponse>(
        `${this._apiUrl}/documents/${esn}/upload-url/${encodedFilename}`
      )
      .pipe(retry(1));
  }

  public upsertDocument(documentUpsertRequest: DocumentUpsertRequest): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/documents/document`, documentUpsertRequest, {responseType: 'json'});
  }

  public getCommnets(upc: string): Observable<CommentDetail[]> {
    return this.httpClient.get<any>(`${this._apiUrl}/comments/${upc}`)
    .pipe(retry(2));
  }

  public saveComment(request: any, upc: string): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/comments/${upc}`, request, {responseType: 'json'});
  }

  public deleteDocument(document: Document): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${this._apiUrl}/documents/${document.upc}/${document.uniqueId}`
      )
      .pipe(retry(1));
  }

  public getComparableUpcPricing(comparableUpc: any): Observable<any> {
    const url = `${this._apiUrl}/linepricing/${comparableUpc}/pricing`;
    return this.httpClient.get<any>(url)
    .pipe(retry(2));
  }

  public getLinkedDraftProducts(comparableUpc: any): Observable<any> {
    const url = `${this._apiUrl}/linepricing/${comparableUpc}`;
    return this.httpClient.get<any>(url)
    .pipe(retry(2));
  }

  public getPricingMargins(division: string, className: string, dcNumbers: any) {
    className = className.substring(0, 25);
    return this.httpClient
      .post(`${this._apiPricingManagemenrUrl}/dynamicpricing/margins/${division}/${className}`,
      dcNumbers, {responseType: 'json'});
  }

  public getReviewBrandStatus(brandCode: any): Observable<any> {
    return this.httpClient.get<any>(`${this._apiUrl}/brand/${brandCode}`)
      .pipe(retry(2));
  }

  public approveNewBrand(request: any): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/brand`, request, { responseType: 'json' });
  }

  public approveNewBrandSupplier( esn: string, request: any): Observable<any> {
    return this.httpClient
      .patch(`${this._supplierUrl}/${esn}`, request, { responseType: 'json' });
  }

  public checkInnerGTINExists(gtin: string): Observable<any> {
    const url = `${this._apiUrl}/gtin/check/${gtin}`;
    return this.httpClient.get<any>(url);
  }

  public getProductSnapshot(upc: string): Observable<Payload<{snapShot: ProductSnapshot}>> {
    return this.httpClient
      .get<Payload<any>>(`${this._apiNewProductUrl}/snapshot/${upc}`);
  }

  public getNewProductHistoryList(upc: string): Observable<CommentDetail[]> {
    return this.httpClient.get<any>(`${this._apiNewProductUrl}/${upc}/audit`)
    .pipe(retry(2));
  }

  getDCProductGroupsForBulkActivation(supplierNumber: any): Observable<any> {
    return this.httpClient.get<any>(`${this._apiEnterpriseUrl}/suppliers/${supplierNumber}/product-groups/flattened`)
      .pipe(catchError(() => of({data: []})), retry(2));
  }

  bulkDCActivation(bulkDCActivationParams): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/dc/activate`
       , bulkDCActivationParams, {responseType: 'json'});
  }

  dCActivationPreValidation(upc: string): Observable<any> {
    return this.httpClient
      .get(`${this._apiUrl}/validate/${upc}`, {responseType: 'json'});
  }

  saveBulkDCSubstituteProduct(params): Observable<any> {
    return this.httpClient.post(`${this._apiEnterpriseUrl}/substitution-upc/bulk-update`, params, {responseType: 'json'});
  }

  public createPriceGroup(request: any): Observable<any> {
    return this.httpClient
      .post(`${this._apiUrl}/linepricing/pricegroup`, request, {responseType: 'json'});
  }

  public getEachDimensionWeightWarning(priceGroup: any): Observable<any> {
    return this.httpClient .get(`${this._apiUrl}/linepricing/eachdimensionweight/${priceGroup}`, {responseType: 'json'})
    .pipe(retry(2));
  }

  getSlimProductDetails(esn: string): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        `${this._apiEnterpriseUrl}/${esn}`
      )
      .pipe(retry(1));
  }

  updatePriceGroups(request: any): Observable<any> {
    return this.httpClient.put(`${this._apiNewProductUrl}/price-groups`, request, {responseType: 'json'});
  }

  getDcProductsCostFactor(upc: string): Observable<any> {
     return this.httpClient.get(`${this._apiNewProductUrl}/costfactor/${upc}`, {responseType: 'json'});
 }

 getIxOneProductUpdateData(request: any): Observable<any> {
    return this.httpClient.post(`${this._apiIxOneUrl}/products/update-product-data`, request, {responseType: 'json'});
 }

  checkProductImageExist(imageUrl: string): Observable<any> {
    return this.httpClient.get<any>(`${imageUrl}`);
  }

  searchCutAndWrapProducts(
    searchString: string,
    dc: string, isCutWrapMakeKit: boolean
  ): Observable<Payload<Array<any>>> {
    let params = new HttpParams()
      .set('search', searchString)
      .set('dc', dc)
      .set('isCutWrapMakeKit', isCutWrapMakeKit);

    return this.httpClient.get<any>(this._apiCutWrapProductUrl, { params: params }).pipe(retry(2));
  }

  public getActiveAssociatedProducts(buyProductUpc: string): Observable<CutAndWrapAssociatesMakeProductGridDataItem[]> {
    const url = `${this._apiCutWrapProductUrl}/makeUpcs/${buyProductUpc}`;
    return this.httpClient.get<CutAndWrapAssociatesMakeProductGridDataItem[]>(url)
    .pipe(retry(2));
  }
}
