import { environment } from '../../../../../environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  addNoteDCProductGroupForm,
  addNoteGeneralForm,
  addNoteProductGroupForm,
  addNoteTermsForm,
  attemptCancelEdit,
  clickTermsRefreshButton,
  confirmCancelEdit,
  editSupplier,
  hideAddProductGroupModal,
  hideAlertMessage,
  loadSupplierDetail,
  saveDCProductGroupForm,
  saveGeneralForm,
  saveNewProductGroupForm,
  saveProductGroupForm,
  saveTermsForm,
  setCurrentTab,
  showAddProductGroupModal,
  showDeleteSupplierModal,
  showGeneralTabOnBoardingStatusModal,
  showNotesModal,
  showSubmitModal,
  showSupplierStatusChangeModal,
  showTermsNewContractForm,
  showUnsavedChangesModal,
  submitSupplier,
  supplierStatusChangeUpdate,
  toggleNavigationPanel,
  updateContractDateTermsForm,
  userClickExportSupplierDataButton,
  validateHeadquarterAddress,
  validatePickupAddress,
  validateRemittanceAddress,
} from '../../store/supplier-detail.actions';
import {
  getEditNewAddDCProductGroup,
  getGeneralFormIsValidFlag,
  getHeadquarterAddressIsDirtyFlag,
  getHeadquarterAddressValidationInProgress,
  getNewDCProductGroupFormIsDirty,
  getOnProductGroupsDetailsView,
  getPickupAddressIsDirtyFlag,
  getPickupAddressValidationInProgress,
  getRemittanceAddressIsDirtyFlag,
  getRemittanceAddressValidationInProgress,
  getSelectedProductGroupView,
  getTermFormIsValidFlag,
  hasBrokerFormWarning,
  hasRemittanceAddressContact,
  selectAlertMessage,
  selectCurrentTab,
  selectdisplayTabPanel,
  selectEditMode,
  selectGeneralInfoIsLoading,
  selectIsFormValid,
  selectIsSupplierReadyToSubmit,
  selectLeadTimeAlert,
  selectOnBoardingStatus,
  selectSaveInProgress,
  selectShowDeleteSupplierModal,
  selectShowNewContractModal,
  selectShowNotesModal,
  selectShowSubmitModal,
  selectShowSupplierStatusChangeModal,
  selectSubmitInProgress,
  selectSupplierDetail,
  selectSupplierValidatedTabs,
  selectUnsavedChangesPending,
  selectValidationError
} from '../../store/supplier-detail.selectors';
import { SupplierDetail } from '../../models/supplier-detail';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { AlertMessage } from '../../../../models/alert-message';
import { Constants } from '../../../../constants/constants';
import { PermissionsService } from '@app/services/permissions.service'; 
import { AlertTypes } from '@kehe/phoenix-notifications';
import { DropdownOption } from './../../../../models/dropdown-option';
import { PurchasingOptionHostApp, PurchasingOptionsFacade } from '@kehe/purchasing-options-lib';
import { PurchasingOptionsViewMode } from '../../models/purchasing-options/purchasing-options-view-mode.enum';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { selectKendoBreadCrumbs } from '@app/shared/state/breadcrumbs/breadcrumbs.selectors';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { ProductGroupViews } from '../../models/product-group-views';
import { isDraft, isInvitedDraftSupplier, isInvitedSupplier } from '../../utilities/supplier-utils';
import { SupplierAction } from '../../models/supplier-action';
import { OnBoardingStatus } from '../../models/onboarding-status';
import { isTabApproved, isTabInReview, isTabNotSubmitted, isTabRejected } from '../../utilities/onboarding-utils';
import { IKeheNavigationTab } from '@kehe/phoenix-navigation-panel';
import { showAddSupplierContactModal } from '../../../supplier-contacts/store/supplier-contacts.actions';
import { DocusignStatus } from '../../models/docusign-status';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { downloadNewContractTemplate, hideDocumentsAlertMessage } from '../../../supplier-documents/store/supplier-documents.actions';
import {
  selectDocumentsAlertMessage,
  selectDocumentsTabLiveStatus,
  selectSIFNotSubmitted,
  selectSIFReadyForReview
} from '../../../supplier-documents/store/supplier-documents.selectors';
import { OnboardingTabStatuses } from '../../../../models/onboarding-tab-statuses';
import { selectCloneDCPGHasChanges, selectCloneDCPGPickupChanged, selectCloneDCPGSaveError, selectCloneDCPGSaveInProgress, selectCloneDCPGValid } from '../../../product-groups/store/product-groups.selectors';
import { cloneDCPGSave } from '../../../product-groups/store/product-groups.actions';
import { brokerAddButtonClicked } from '../../components/broker-tab-v2/broker-actions';
import { associatedSupplierAddButtonClicked } from '../../components/associated-supplier-tab/associated-supplier.actions';
import { FeatureFlags } from '../../../../feature-flag';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { clearKendoBreadCrumbs, clickedKendoBreadCrumbs, updateKendoBreadCrumbs } from './supplier-detail-page.actions';

@Component({
  selector: 'app-supplier-detail-page',
  templateUrl: './supplier-detail-page.component.html',
  styleUrls: ['./supplier-detail-page.component.scss']
})
export class SupplierDetailPageComponent extends Destroyable implements OnInit, OnDestroy {

  readonly termsChangeWarning = Constants.TermsChangeWarning;
  readonly alertTypes = AlertTypes;
  onboardingStatuses: OnBoardingStatus[] = [];

  public cloneDCPGSaveError$ = this._store.select(selectCloneDCPGSaveError);

  public supplier: SupplierDetail;
  public editMode: boolean;
  public generalLoading: boolean;
  public isSaveInProgress: boolean;
  public isSubmitInProgress: boolean;
  public alertMessage: AlertMessage;
  public leadTimeAlert: AlertMessage;
  public documentsAlertMessage: AlertMessage;
  public hasPendingChanges: boolean;
  public detailTabs: IKeheNavigationTab[] = [];
  public detailTabsList: DropdownOption[] = [];
  public displayTabPanel$: Observable<boolean>;
  public onProductGroupsDetailsView: boolean;
  public currentTab: SupplierDetailTabs;
  public canUserEdit: boolean;
  public canUserEditGeneralTab: boolean;
  public canUserEditTermsTab: boolean;
  public canUserEditProductGroup: boolean;
  public canUserAddProductGroup: boolean;
  public canUserEditStatus: boolean;
  public canSubmitSupplier: boolean;
  public canDeleteSupplier: boolean;
  private dcList = [];
  private purchasingOptionActionButtons = [];
  public actionButtonStatus = [];

  public isHeadquarterAddressDirty: boolean;
  public isHeadquarterAddressValidationInProgress: boolean;

  public isRemittanceAddressDirty: boolean;
  public isRemittanceAddressValidationInProgress: boolean;

  public isGeneralFormValid: boolean;
  public isTermsFormValid: boolean;

  public isNavigationTabClicked: boolean;
  public navigationTabClicked: any;
  public unSavedChangesModalSubmitButtonName: string;
  public unSavedChangesModalSecondButtonName: string;
  public unSavedChangesModalMessage: string;
  public isRouteChangeClicked: boolean;
  public routeAttemptedToNavigate: string;
  public isFormValid: boolean;

  public isPickupAddressDirty: boolean;
  public isPickupAddressValidationInProgress: boolean;
  public esn: string;
  public showModal$: Observable<boolean>;
  public showSubmitModal$: Observable<boolean>;
  private selectedProductGroupView: ProductGroupViews;
  showNewDCProductGroupHeader: boolean;
  editNewAddDCProductGroup = false;
  private purchasingOptionViewMode: PurchasingOptionsViewMode = PurchasingOptionsViewMode.list;
  private isPurchasingOptionSaveEnabled: boolean;
  public items$: Observable<BreadCrumbItem[]>;
  public isBreadCrumbClicked: boolean;
  public breadCrumbAttemptedToNavigate: BreadCrumbItem;
  public newAddDCProductGroupFormIsDirty: boolean;
  public isStatusChangeInProgress = false;
  public statusChangeModalSubmitButtonName: string;
  public statusChangeModalMessage: string;
  public showStatusChangeModal = false;
  public statusChangeHeader: string;
  public supplierStatusActive: boolean;
  public headerAlerts = [];
  private purchasingOptionHeaderAlerts = [];
  public supplierValidationError = null;
  public actionButtons = [];
  documentTabLiveStatus: OnboardingTabStatuses;
  dpiExportFF = false;

  public supplierValidatedTabs: any[] = [];
  isSupplierReadyToSubmit: boolean;
  private brokerV2FeatureFlag = false;
  hasRemittanceAddressContact = false;

  showNewContractModal = false;
  hasBrokerFormWarning = false;
  overFlowButtonItems: OptionMenuItem[] = [];
  showDeleteSupplierModal = false;

  public purchasingOptionsMenuOptions$: Observable<OptionMenuItem[]> = this._purOpsFacade.selectHeaderEllipsisMenuItems();
  get canShowActionBar(): boolean {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      return this._permissionsService.userHasAction(Constants.UserActions.PurchasingOptionsUpdate);
    }
    return this.canUserEdit;
  }

  get canShowSubmitButton(): boolean {
    return this.canSubmitSupplier && !this.editMode;
  }

  get canShowSaveCancelButton(): boolean {
    if (this.currentTab === SupplierDetailTabs.brands) {
      return false;
    }
    if (!this.editMode) {
      return false;
    }
    if (this.currentTab === SupplierDetailTabs.broker && isDraft(this.supplier)) {
      return true;
    }
    return this.currentTab !== SupplierDetailTabs.broker;
  }

  get isPurchasingOptions(): boolean {
    return this.currentTab === SupplierDetailTabs.purchasingOptions;
  }

  get canShowEditButton(): boolean {
    if (this.editMode) {
      return false;
    }

    switch (this.currentTab) {
      case SupplierDetailTabs.users:
      case SupplierDetailTabs.brands:
      case SupplierDetailTabs.legalSignatures:
      case SupplierDetailTabs.contacts:
      case SupplierDetailTabs.history:
      case SupplierDetailTabs.documents:
      case SupplierDetailTabs.performance:
      case SupplierDetailTabs.locations:
      case SupplierDetailTabs.purchasingInfo:
      case SupplierDetailTabs.broker:
        return false;
      case SupplierDetailTabs.purchasingOptions:
        return this.purchasingOptionViewMode === PurchasingOptionsViewMode.view;

      case SupplierDetailTabs.productGroups:
        return this.onProductGroupsDetailsView && this.canUserEditProductGroup;

      case SupplierDetailTabs.supplierTerms:
        return this.canUserEditTermsTab;

      case SupplierDetailTabs.general:
        return this.canUserEditGeneralTab;

      default:
        return true;
    }
  }

  get disableEditButton(): boolean {
    switch (this.currentTab) {
      case SupplierDetailTabs.supplierTerms:
        if (!isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general) && isInvitedSupplier(this.supplier)) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  get canShowAddButton(): boolean {
    if (this.currentTab === SupplierDetailTabs.contacts && isDraft(this.supplier)) {
      return true;
    }
    if (this.currentTab === SupplierDetailTabs.brands) {
      return false;
    }
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      return this.purchasingOptionViewMode === PurchasingOptionsViewMode.list;
    }
    if (this.currentTab === SupplierDetailTabs.broker) {
      return true;
    }
    return false;
  }

  get canShowAddProductGroupButton(): boolean {
    return this.currentTab === SupplierDetailTabs.productGroups
      && this.canUserAddProductGroup
      && !this.onProductGroupsDetailsView
      && !this.editMode;
  }

  get canAddProductGroup() {
    if (isInvitedDraftSupplier(this.supplier)) {
      return this.hasRemittanceAddressContact;
    }
    return true;
  }

  get canShowOverflowButton(): boolean {
    if (isDraft(this.supplier)) {
      return true;
    } else {
      return this.canUserEditStatus;
    }
  }

  get isBrokerV2Tab(): boolean {
    return (this.currentTab === SupplierDetailTabs.broker);
  }
  get isAssociatedSuppliersTab(): boolean {
    return (this.currentTab === SupplierDetailTabs.associatedSuppliers);
  }

  setOverFlowButtonItems() {
    if (isDraft(this.supplier)) {
      this.setOverFlowButtonItemsForDraft();
    } else {
      if (this.supplierStatusActive) {
        this.overFlowButtonItems = [
          { name: SupplierAction.DiscontinueSupplier, enabled: true, selected: false, data: SupplierAction.DiscontinueSupplier }
        ];
      } else {
        this.overFlowButtonItems = [
          { name: SupplierAction.ActivateSupplier, enabled: true, selected: false, data: SupplierAction.ActivateSupplier }
        ];
      }

      if (this.currentTab === SupplierDetailTabs.supplierTerms && this._permissionsService.userHasAction(Constants.UserActions.SupplierTermsUpdate)) {
        this.overFlowButtonItems.push({
          name: SupplierAction.RefreshTerms, enabled: true, selected: false, data: SupplierAction.RefreshTerms,
        });
      }
      if (this.dpiExportFF && this.supplierStatusActive && this._permissionsService.userHasAction(Constants.UserActions.ExportSupplierData)) {
        this.overFlowButtonItems.push({
          name: SupplierAction.ExportDPI, enabled: true, selected: false, data: SupplierAction.ExportDPI
        })
      }
    }
  }

  setOverFlowButtonItemsForDraft() {
    if (SupplierDetailTabs.general === this.currentTab) {
      const isApproveEnabled = !isTabNotSubmitted(this.onboardingStatuses, SupplierDetailTabs.general) && !isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general);
      const isRejectEnabled = isTabInReview(this.onboardingStatuses, SupplierDetailTabs.general) || isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general);

      this.overFlowButtonItems = [];
      if (isApproveEnabled) {
        this.overFlowButtonItems.push(
          { name: SupplierAction.ApproveGeneralTab, enabled: true, selected: false, data: SupplierAction.ApproveGeneralTab }
        );
      }
      if (isRejectEnabled) {
        this.overFlowButtonItems.push(
          { name: SupplierAction.RejectGeneralTab, enabled: true, selected: false, data: SupplierAction.RejectGeneralTab }
        );
      }
    } else {
      this.overFlowButtonItems = [];
    }
    if (this.canDeleteSupplier) {
      this.overFlowButtonItems.push(
        { name: SupplierAction.DeleteSupplier, enabled: true, selected: false, data: SupplierAction.DeleteSupplier }
      );
    }
  }

  get canShowEllipsis(): boolean {
    return this.currentTab !== SupplierDetailTabs.purchasingOptions;
  }

  get leftActionButtons() {
    return this.actionButtons.filter(b => b.placement === 'left');
  }

  get rightActionButtons() {
    return this.actionButtons.filter(b => b.placement !== 'left');
  }

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private _store: Store,
    private _permissionsService: PermissionsService,
    private _purOpsFacade: PurchasingOptionsFacade,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      select(selectIsFeatureFlagOn(FeatureFlags.BrokerV2.key)),
      takeUntil(this.destroy$)).subscribe(val => {
        this.brokerV2FeatureFlag = val;
      });
    this.setTabList();
    this.showModal$ = this._store.pipe(select(selectShowNotesModal));
    this.showSubmitModal$ = this._store.pipe(select(selectShowSubmitModal));
    this.newAddDCProductGroupFormIsDirty = false;
    this.setTabList();

    this._route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params: ParamMap) => of(params.get('esn')))
      )
      .subscribe((esn) => {
        this._store.dispatch(loadSupplierDetail({ esn }));
        this.esn = esn;
        this.setOverFlowButtonItems();
      });

    this.items$ = this._store.pipe(
      select(selectKendoBreadCrumbs),
      switchMap((breadcrumbs) => {
        return this._store.select(selectGeneralInfoIsLoading).pipe(
          filter((v) => !v),
          map(() => {
            return breadcrumbs;
          })
        );
      }),
    );

    this._store
    .pipe(
      select(selectIsFeatureFlagOn(FeatureFlags.PurchasingOptionsIgnoreBracket.key)),
      takeUntil(this.destroy$)
    )
    .subscribe((value) => {
      this._purOpsFacade.updateFeatureFlags({
        [FeatureFlags.PurchasingOptionsIgnoreBracket.key]: value
      });
    });


    this._store
    .pipe(
      select(selectIsFeatureFlagOn(FeatureFlags.EnterprisePurchasingOptionRequiredPriceChanges.key)),
      takeUntil(this.destroy$)
    )
    .subscribe((value) => {
      this._purOpsFacade.updateFeatureFlags({
        [FeatureFlags.EnterprisePurchasingOptionRequiredPriceChanges.key]: value
      });
    });

    combineLatest([
      this._store.select(selectSaveInProgress),
      this._store.select(selectCloneDCPGSaveInProgress),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([val, cloneDCPGInProgress]) => {
        this.isSaveInProgress = val || cloneDCPGInProgress;
      });

    this._store.pipe(
      select(selectSubmitInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isSubmitInProgress = val;
      });

    this._store.pipe(
      select(selectLeadTimeAlert),
      takeUntil(this.destroy$)
    ).subscribe(val => {
      this.leadTimeAlert = val;
    });

    this._store.pipe(
      select(selectAlertMessage),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.alertMessage = val;
      });
    this._store.pipe(
      select(selectDocumentsAlertMessage),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.documentsAlertMessage = val;
      });
    this._store.pipe(
      select(selectDocumentsTabLiveStatus),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.documentTabLiveStatus = val;
      });
    this._store
      .pipe(select(selectGeneralInfoIsLoading), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.generalLoading = val;
      });
    this._store.select(selectIsFeatureFlagOn(FeatureFlags.SupplierDPIExportReport.key))
      .subscribe(val => {
        this.dpiExportFF = val
      });

    this._store.pipe(select(DistributionCenterSelectors.selectActiveDistributionCentersTrimmedNameAndNumber), 
      takeUntil(this.destroy$),
      map(dcs => { 
        return dcs.map(dc => {
          return { Name: dc.label, Number: dc.value, IsActive: dc.isActive, IsNtsActive: dc.isNtsActive } // purchasing options expects pascal-cased properties
        })
      }),
    ).subscribe(val => {
        this.dcList = val;
        this.setPurchasingOptionInitialData();
      });

    this._store
      .pipe(
        select(selectSupplierDetail),
        takeUntil(this.destroy$))
      .subscribe((val) => {
        this.supplier = val;
        if (val) {
          this.setTabList();
          this.supplierStatusActive = val.status === 'Active';
          val.statusTag.text = this.getSupplierTag(val.status);
          this._store.dispatch(updateKendoBreadCrumbs({
            item:
            {
              title: `supplier/${this.esn}`,
              text: `Supplier (${this.esn})`
            }
          }));
          this.setUserPermissions();
          this.setPurchasingOptionInitialData();
          this.setOverFlowButtonItems();
        }

      });

    this._store.pipe(
      select(selectEditMode),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.editMode = val;
      });

    combineLatest([
      this._store.select(selectUnsavedChangesPending),
      this._store.select(selectCloneDCPGHasChanges),
      this._store.select(getSelectedProductGroupView),
    ])
    .pipe(takeUntil(this.destroy$))
      .subscribe(([val, cloneDCPG, view]) => {
        if (view === ProductGroupViews.CloneDCProductGroupView) {
          this.hasPendingChanges = cloneDCPG;
          return;
        }
        this.hasPendingChanges = val;
        return;
      });

    combineLatest([
      this._store.select(selectIsFormValid),
      this._store.select(selectCloneDCPGValid),
      this._store.select(getSelectedProductGroupView),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([val, cloneDCPGValid, view]) => {
        if (view === ProductGroupViews.CloneDCProductGroupView) {
          this.isFormValid = cloneDCPGValid;
          return;
        }
        this.isFormValid = val;
        return;
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getEditNewAddDCProductGroup))
      .subscribe(val => {
        this.editNewAddDCProductGroup = val;
        this.showNewDCProductGroupHeader = val;
      });

    this.displayTabPanel$ = this._store.pipe(select(selectdisplayTabPanel));

    this._store.pipe(select(getOnProductGroupsDetailsView)).pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.onProductGroupsDetailsView = value;
    });

    this._store.pipe(select(selectCurrentTab)).pipe(takeUntil(this.destroy$)).subscribe(tab => {
      this.currentTab = tab;

      this.updateActionButtons();
      this.setOverFlowButtonItems();
    });

    combineLatest([
      this._store.pipe(select(selectSIFNotSubmitted)),
      this._store.pipe(select(selectCurrentTab)),
    ])
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(([value, tab]) => {
        if (value && tab === SupplierDetailTabs.purchasingInfo) {
          this.headerAlerts = [
            {
              type: AlertTypes.Warning,
              message: 'The Supplier Information Form is not ready for review.'
            }
          ];
        } else {
          this.headerAlerts = [];
        }
      });
    combineLatest([
      this._store.pipe(select(selectSIFReadyForReview)),
      this._store.pipe(select(selectCurrentTab)),
    ])
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(([value, tab]) => {
        if (value && tab === SupplierDetailTabs.purchasingInfo) {
          this.headerAlerts = [
            {
              type: AlertTypes.Information,
              message: 'The Supplier Information Form is ready for KeHE review.'
            }
          ];
        } else {
          this.headerAlerts = [];
        }
      });

    this._store.pipe(
      select(getHeadquarterAddressIsDirtyFlag),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isHeadquarterAddressDirty = val;
      });

    this._store.pipe(
      select(getHeadquarterAddressValidationInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isHeadquarterAddressValidationInProgress = val;
      });

    this._store.pipe(
      select(getRemittanceAddressIsDirtyFlag),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isRemittanceAddressDirty = val;
      });

    this._store.pipe(
      select(getRemittanceAddressValidationInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isRemittanceAddressValidationInProgress = val;
      });

    this._store.pipe(
      select(getGeneralFormIsValidFlag),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isGeneralFormValid = val;
      });

    this._store.pipe(
      select(getTermFormIsValidFlag),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isTermsFormValid = val;
      });

    combineLatest([
      this._store.select(getPickupAddressIsDirtyFlag),
      this._store.select(selectCloneDCPGPickupChanged),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([val, cloneDCPGPickupChanged]) => {
        this.isPickupAddressDirty = val || cloneDCPGPickupChanged;
      });

    this._store.pipe(
      select(getPickupAddressValidationInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isPickupAddressValidationInProgress = val;
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSelectedProductGroupView))
      .subscribe(val => {
        this.selectedProductGroupView = val;
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getNewDCProductGroupFormIsDirty))
      .subscribe(val => {
        this.newAddDCProductGroupFormIsDirty = val;
      });


    this._store.pipe(
      select(selectShowSupplierStatusChangeModal),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.showStatusChangeModal = val;
      });


    this._store.pipe(
      select(selectValidationError),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.supplierValidationError = val;
      });

    this._store.pipe(
      select(selectSupplierValidatedTabs),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.supplierValidatedTabs = val;
        this.setOverFlowButtonItems();
      });
    this._store.pipe(
      select(selectIsSupplierReadyToSubmit),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isSupplierReadyToSubmit = val;
      });
    this._store.pipe(
      select(selectOnBoardingStatus),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.onboardingStatuses = val;
        this.setOverFlowButtonItems();
      });
    this.addPurchasingOptionSubscription();

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowNewContractModal))
      .subscribe(val => {
        this.showNewContractModal = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(hasBrokerFormWarning))
      .subscribe(val => {
        this.hasBrokerFormWarning = val;
      });

    this._store.select(selectIsFeatureFlagOn(FeatureFlags.SupplierPurchasingOptionsActiveEdit.key))
    .pipe(takeUntil(this.destroy$))
    .subscribe((key) => {
        this._purOpsFacade.updateFeatureFlags({
            [FeatureFlags.SupplierPurchasingOptionsActiveEdit.key]: key
        });
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowDeleteSupplierModal))
      .subscribe(val => {
        this.showDeleteSupplierModal = val;
      });

    this._store
      .pipe(takeUntil(this.destroy$), select(hasRemittanceAddressContact))
      .subscribe((value) => {
        this.hasRemittanceAddressContact = value;
      });
  }

  updateActionButtons() {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      this.actionButtons = this.purchasingOptionActionButtons;
    } else {
      this.actionButtons = [];
    }
  }

  private setUserPermissions() {
    const hasSupplierCreate = this._permissionsService.userHasAction(Constants.UserActions.SupplierCreate);
    this.canSubmitSupplier = isDraft(this.supplier) && hasSupplierCreate;

    // Umbrella edit
    this.canUserEdit = this._permissionsService.userHasAction(Constants.UserActions.SupplierUpdate)
      || this.canSubmitSupplier;

    // General tab
    this.canUserEditGeneralTab = this._permissionsService.userHasAction(Constants.UserActions.SupplierGeneralSummaryUpdate)
      || this._permissionsService.userHasAction(Constants.UserActions.SupplierGeneralSupplierHeadquartersUpdate)
      || this._permissionsService.userHasAction(Constants.UserActions.EdiUpdate);

    // Terms tab
    this.canUserEditTermsTab = this._permissionsService.userHasAction(Constants.UserActions.SupplierTermsUpdate);

    // Product groups
    this.canUserAddProductGroup = this._permissionsService.userHasAction(Constants.UserActions.SupplierProductGroupCreate);
    this.canUserEditProductGroup = this._permissionsService.userHasAction(Constants.UserActions.SupplierProductGroupUpdate);

    // Status
    this.canUserEditStatus = this._permissionsService.userHasAction(Constants.UserActions.SupplierStatusUpdate);

    // Supplier Delete
    this.canDeleteSupplier = this._permissionsService.userHasAction(Constants.UserActions.SupplierDelete);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.hideAlertMessage();
    this._store.dispatch(clearKendoBreadCrumbs());
  }

  get tabs(): typeof SupplierDetailTabs {
    return SupplierDetailTabs;
  }

  onActivate() {
    const firstPath = this._route.firstChild.snapshot.url[0].path;

    const tabOption = SupplierDetailTabs[firstPath];

    if (this.currentTab !== tabOption) {
      this._store.dispatch(setCurrentTab({ tab: tabOption }));
    }
    this.setBreadcrumbs();
  }

  // add the purchasing option subscriptions after loading purchasing options module
  // since we are loading the module as lazy loading
  // if we add the subscriptions before loading the modules, it won't work
  addPurchasingOptionSubscription() {
    this._purOpsFacade.requestedInitialData().pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (val) {
          this.setPurchasingOptionConfigData();
        }
      });
    this._purOpsFacade.selectPurchasingOptionsViewMode()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        this.purchasingOptionViewMode = val;
      });
    this._purOpsFacade.selectEnablePurchasingOptionsSave()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        this.isPurchasingOptionSaveEnabled = val;
      });
    this._purOpsFacade.selectPageAlerts().pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.purchasingOptionHeaderAlerts = val;
        this.updatePageHeaderAlerts();
      });
    this._purOpsFacade.selectHeaderActionButtons().pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.purchasingOptionActionButtons = val;
        this.updateActionButtons();
      });
  }

  setPurchasingOptionConfigData() {
    this._purOpsFacade.setConfigData({
      hostApp: PurchasingOptionHostApp.Enterprise,
      esn: this.esn,
      smartyStreetsKey: environment.smartyStreetsKey,
      supplierApi: environment.supplierApi,
      purchasingOptionApi: environment.purchasingOptionsApi,
    });
    this.setPurchasingOptionInitialData();
  }

  setPurchasingOptionInitialData() {
    if (this.supplier) {
      this._purOpsFacade.setInitialData({
        brands: this.supplier.brands,
        supplierContacts: this.supplier.contacts as any,
        dcList: this.dcList,
        masterVersionNumber: this.supplier.masterVersionNumber
      });
    }
  }

  updatePageHeaderAlerts() {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      this.headerAlerts = this.purchasingOptionHeaderAlerts;
    } else {
      this.headerAlerts = [];
    }
  }

  actionButtonClicked(item) {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      this._purOpsFacade.clickedHeaderActionButton(item);
    }
  }

  private setBreadcrumbs(): void {
    const firstPath = this._route.firstChild.snapshot.url[0].path;
    const tabOption = SupplierDetailTabs[firstPath];
    this._store.dispatch(clearKendoBreadCrumbs());
    this._store.dispatch(updateKendoBreadCrumbs({
      item:
      {
        title: 'supplier/',
        text: 'Manage Supplier'
      }
    }));
    this._store.dispatch(updateKendoBreadCrumbs({
      item:
      {
        title: `supplier/${this.esn}`,
        text: `Supplier (${this.esn})`
      }
    }));
    this._store.dispatch(updateKendoBreadCrumbs({
      item:
      {
        title: `supplier/${this.esn}/${firstPath}`,
        text: `${tabOption}`
      }
    }));

    if (this._route.firstChild.snapshot.url.length > 1) {
      const secondPath = this._route.firstChild.snapshot.url[1].path;
      if (firstPath === 'productGroups') {
        let crumbText = `Product Group Detail (${secondPath})`;
        if (secondPath === 'edit') {
          crumbText = 'Product Group Detail';
        }
        this._store.dispatch(updateKendoBreadCrumbs({
          item:
          {
            title: `supplier/${this.esn}/productGroups/`,
            text: crumbText
          }
        }));
      }
    }
  }

  public hideAlertMessage(): void {
    this._store.dispatch(hideAlertMessage());
  }

  public edit(): void {
    switch(this.currentTab) {
      case SupplierDetailTabs.purchasingOptions:
        this._purOpsFacade.changePurchasingOptionViewMode(PurchasingOptionsViewMode.edit);
        break;
      default:
        this._store.dispatch(editSupplier());
    }
  };

  public cancel(): void {
    if (this.isSaveInProgress) {
      return;
    }
    if (this.currentTab === SupplierDetailTabs.purchasingOptions && !this.hasPendingChanges) {
      this._purOpsFacade.changePurchasingOptionViewMode(PurchasingOptionsViewMode.view);
      return;
    }
    this.handleAttemptCancelPendingEdits();
    if (this.editNewAddDCProductGroup && !this.newAddDCProductGroupFormIsDirty) {
      this._store.dispatch(hideAddProductGroupModal());
      const priorRoute = this.router.url.replace('/edit', '');

      this.router.navigate([priorRoute]);
    }

    this.setBreadcrumbs();
  }

  public handleAttemptCancelPendingEdits() {
    this.routeAttemptedToNavigate = '';
    this.isRouteChangeClicked = false;
    this.isNavigationTabClicked = false;
    this.isBreadCrumbClicked = false;
    this.unSavedChangesModalSecondButtonName = 'Discard Changes';
    this.unSavedChangesModalSubmitButtonName = 'Continue Editing';
    this.unSavedChangesModalMessage = `You have unsaved changes. Do you want to discard your changes? Any unsaved changes will be lost.`;
    this._store.dispatch(attemptCancelEdit());
  }

  public attemptNavigatePendingEdits(event) {
    this.isBreadCrumbClicked = false;
    this.routeAttemptedToNavigate = '';
    this.unSavedChangesModalSecondButtonName = 'Leave Page';
    this.unSavedChangesModalSubmitButtonName = 'Stay on Page';
    this.unSavedChangesModalMessage = `You have unsaved changes. Do you want to leave this page without saving? Any unsaved changes will be lost.`;
    this.isNavigationTabClicked = true;
    this.navigationTabClicked = event;
    this._store.dispatch(showUnsavedChangesModal());
    if (this.newAddDCProductGroupFormIsDirty) {
      this._store.dispatch(attemptCancelEdit());
    }
  }

  public navigationPanelToggled() {
    this._store.dispatch(toggleNavigationPanel());
  }

  public attemptCancelEditModal(): void {
    this.unSavedChangesModalSubmitButtonName = 'Stay on Page';
    this.unSavedChangesModalSecondButtonName = `Leave Page`;
    this.unSavedChangesModalMessage = `You have unsaved changes. Do you want to leave this page without saving? Any unsaved changes will be lost.`;
    this._store.dispatch(attemptCancelEdit());
  }

  public attemptPageRoutingPendingEdits() {
    this.isNavigationTabClicked = false;
    this.isBreadCrumbClicked = false;
    this.navigationTabClicked = event;
    this.unSavedChangesModalSecondButtonName = 'Leave Page';
    this.unSavedChangesModalSubmitButtonName = 'Stay on Page';
    this.unSavedChangesModalMessage = `You have unsaved changes. Do you want to leave this page without saving? Any unsaved changes will be lost.`;
    this._store.dispatch(attemptCancelEdit());
  }

  public attemptBreadCrumbNavigatePendingEdits(item: BreadCrumbItem) {
    this.isRouteChangeClicked = false;
    this.isNavigationTabClicked = false;
    this.unSavedChangesModalSecondButtonName = 'Leave Page';
    this.unSavedChangesModalSubmitButtonName = 'Stay on Page';
    this.unSavedChangesModalMessage = `You have unsaved changes. Do you want to leave this page without saving? Any unsaved changes will be lost.`;
    this.isBreadCrumbClicked = true;
    this.breadCrumbAttemptedToNavigate = item;
    this._store.dispatch(attemptCancelEdit());
  }

  public isSaveDisabled() {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      const tabActionButtons = this.actionButtonStatus[this.currentTab];
      if (tabActionButtons && tabActionButtons.saveButton !== undefined) {
        return tabActionButtons.saveButton.disabled;
      }
    }
    if (this.selectedProductGroupView === ProductGroupViews.CloneDCProductGroupView) {
      return !this.isFormValid || this.isSaveInProgress;
    }

    return (!this.isFormValid || !this.hasPendingChanges || this.isSaveInProgress);
  }

  public isSubmitDisabled() {
    if (!this.isSaveDisabled()) {
      return true;
    }
    // need to check the state of the supplier
    // make sure it has an ESN, at least 1 valid product group
    // , at least 1 valid DC Product Group
    return !this.isSupplierReadyToSubmit || this.isSubmitInProgress || this.isSaveInProgress;
  }
  //   get isSaveInProgress(): boolean {
  //   const tabActionButtons = this.actionButtonStatus[this.currentTab];
  //   if (tabActionButtons && tabActionButtons.saveButton !== undefined) {
  //     return tabActionButtons.saveButton.progress;
  //   }
  //   return false;
  // }


  get getSubmitButtonText() {

    return 'Activate';
  }

  canDeactivate(url): Observable<boolean> | boolean {
    if (this.hasPendingChanges) {
      this.isRouteChangeClicked = true;
      this.routeAttemptedToNavigate = url;
      this.attemptPageRoutingPendingEdits();
    } else {
      return true;
    }
  }

  onSaveButtonClick() {
    if (this.isSaveDisabled() || this.isSaveInProgress) {
      return;
    }
    if (this.isHeadquarterAddressDirty || this.isRemittanceAddressDirty || this.isPickupAddressDirty) {
      this.validateAddress();
    } else {
      this.checkTabAndSave();
    }
  }

  checkTabAndSave() {
    if (this.currentTab === SupplierDetailTabs.purchasingOptions) {
      return;
    }
    if (this.currentTab === SupplierDetailTabs.productGroups) {
      return this.checkDCPGTabAndSave();
    }
    if (this.currentTab === SupplierDetailTabs.supplierTerms
      && isDraft(this.supplier)
      && isInvitedSupplier(this.supplier)) {
      this._store.dispatch(showTermsNewContractForm());
      return;
    }
    this.defaultSaveActionSave();
  }

  checkDCPGTabAndSave() {
    if (this.editNewAddDCProductGroup) {
      this._store.dispatch(saveNewProductGroupForm());
      return;
    }
    if (ProductGroupViews.AddDCProductGroupView === this.selectedProductGroupView) {
      this._store.dispatch(saveDCProductGroupForm());
      return;
    }
    if (ProductGroupViews.DCProductGroupView === this.selectedProductGroupView) {
      if (isDraft(this.supplier)) {
        this._store.dispatch(saveDCProductGroupForm());
      } else {
        this._store.dispatch(showNotesModal());
      }
      return;
    }
    if (ProductGroupViews.CloneDCProductGroupView === this.selectedProductGroupView) {
      this._store.dispatch(cloneDCPGSave());
      return;
    }
    this.defaultSaveActionSave();
  }

  defaultSaveActionSave() {
    if (isDraft(this.supplier)) {
      const e = {
        currentTab: this.currentTab,
        ticket: null,
        notes: 'N/A',
        hasAddendum: false,
        contractDate: null
      };
      this.onNoteModalSave(e);
      return;
    }
    this._store.dispatch(showNotesModal());
  }

  validateAddress() {
    if (this.isHeadquarterAddressDirty) {
      this._store.dispatch(validateHeadquarterAddress());
    } else if (this.isRemittanceAddressDirty) {
      this._store.dispatch(validateRemittanceAddress());
    } else if (this.isPickupAddressDirty) {
      this._store.dispatch(validatePickupAddress());
    }
  }

  onNoteModalSave(e) {
    const notes = this.setNote(e);
    switch (e.currentTab) {
      case SupplierDetailTabs.general:
        this._store.dispatch(addNoteGeneralForm({ notes: notes }));
        this._store.dispatch(saveGeneralForm());
        break;
      case SupplierDetailTabs.supplierTerms:
        if (e.contractDate) {
          this._store.dispatch(updateContractDateTermsForm({ contractDate: e.contractDate }));
        }
        this._store.dispatch(addNoteTermsForm({ notes: notes, changeType: e.changeType }));
        this._store.dispatch(saveTermsForm({ addendumAsBase64String: null }));
        break;
      case SupplierDetailTabs.productGroups:
        switch (this.selectedProductGroupView) {
          case ProductGroupViews.ProductGroupView: {
            this._store.dispatch(addNoteProductGroupForm({ notes: notes }));
            this._store.dispatch(saveProductGroupForm());
            break;
          }
          case ProductGroupViews.AddDCProductGroupView:
          case ProductGroupViews.DCProductGroupView: {
            this._store.dispatch(addNoteDCProductGroupForm({ notes: notes }));
            this._store.dispatch(saveDCProductGroupForm());
            break;
          }
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  onSubmitModalSave() {
    this._store.dispatch(submitSupplier());
  }

  public checkAndContinueNavigating() {
    if (this.isNavigationTabClicked) {
      if (this.isSaveInProgress) {
        setTimeout(() => this.checkAndContinueNavigating(), 200);
      } else {
        this.navigateToTab(this.navigationTabClicked);
      }
    } else if (this.isRouteChangeClicked) {
      this._store.dispatch(confirmCancelEdit());
      this.router.navigateByUrl(this.routeAttemptedToNavigate);
    } else if (this.isBreadCrumbClicked) {
      this._purOpsFacade.changePurchasingOptionViewMode(PurchasingOptionsViewMode.view);
      this._store.dispatch(confirmCancelEdit());
      this._store.dispatch(clickedKendoBreadCrumbs({ item: this.breadCrumbAttemptedToNavigate }));
    } else if (this.newAddDCProductGroupFormIsDirty) {
      this._store.dispatch(hideAddProductGroupModal());
      const priorRoute = this.router.url.replace('/edit', '');
      this.router.navigate([priorRoute]);
      this._store.dispatch(confirmCancelEdit());
    } else {
      this._store.dispatch(confirmCancelEdit());
    }
  }

  public navigationPanelSetTab(event: SupplierDetailTabs) {
    if (this.isSaveInProgress) {
      return;
    }
    if (this.hasPendingChanges) {
      this.attemptNavigatePendingEdits(event);
    } else {
      this.router.navigate([this.getKeyFromTab(event)], { relativeTo: this._route });
      this._store.dispatch(confirmCancelEdit());
      if (event === SupplierDetailTabs.purchasingOptions) {
        // purchasing options module is a lazy loaded module
        // so update subscriptions after navigating to purchasing options
        // timeout to allow time to load the module and initialize the store
        setTimeout(() => {
          this.addPurchasingOptionSubscription();
        }, 500);
      }
    }
  }

  public navigateToTab(event: SupplierDetailTabs) {
    if (this.isSaveInProgress) {
      return;
    }
    this.isNavigationTabClicked = false;
    this.router.navigate([this.getKeyFromTab(event)], { relativeTo: this._route });
    this._store.dispatch(confirmCancelEdit());
  }

  onAddClick() {
    this._store.dispatch(hideDocumentsAlertMessage());

    switch (this.currentTab) {
      case SupplierDetailTabs.purchasingOptions:
        this._purOpsFacade.showAddPurchasingOptionsModal();
        break;
      case SupplierDetailTabs.contacts:
        if (isDraft(this.supplier)) {
          this._store.dispatch(showAddSupplierContactModal());
        }
        break;
      case SupplierDetailTabs.broker:
        this._store.dispatch(brokerAddButtonClicked())
        break;
      case SupplierDetailTabs.associatedSuppliers:
        this._store.dispatch(associatedSupplierAddButtonClicked())
        break;
      default:
        break;
    }
  }


  onSubmitButtonClick() {
    if (this.isSubmitDisabled()) {
      return;
    }
    this._store.dispatch(showSubmitModal());
  }
  onAddProductGroupClick() {
    this._store.dispatch(showAddProductGroupModal());
  }

  public setTabList() {
    this.detailTabs = [];
    this.detailTabs.push({ tab: SupplierDetailTabs.general });
    this.detailTabs.push({ tab: SupplierDetailTabs.contacts });
    this.detailTabs.push({ tab: SupplierDetailTabs.brands });

    if (this.brokerV2FeatureFlag) {
      this.detailTabs.push({ tab: SupplierDetailTabs.broker });
    }
    if (
      this.brokerV2FeatureFlag &&
      this._permissionsService.userHasAction(
        Constants.UserActions.Vendor_Admin_SS
      )
    ) {
      this.detailTabs.push({ tab: SupplierDetailTabs.associatedSuppliers });
    }
    this.detailTabs.push({ tab: SupplierDetailTabs.supplierTerms });
    this.detailTabs.push({ tab: SupplierDetailTabs.productGroups });

    if (this._permissionsService.userHasAction(Constants.UserActions.PurchasingOptionsRead)) {
      this.detailTabs.push({ tab: SupplierDetailTabs.purchasingOptions });
    }
    this.detailTabs.push({ tab: SupplierDetailTabs.performance });
    this.detailTabs.push({ tab: SupplierDetailTabs.documents });
    this.detailTabs.push({ tab: SupplierDetailTabs.history });
  }



  public setNote(e) {
    const noteParts = new Array<string>();

    if (e.currentTab === SupplierDetailTabs.supplierTerms) {
      noteParts.push(`Addendum: ${e.hasAddendum ? 'Yes' : 'No'}`);
    }

    if (e.ticket) {
      noteParts.push(`Ticket: ${e.ticket}`);
    }

    if (e.notes) {
      noteParts.push(`Reason: ${e.notes}`);
    }

    return noteParts.join(' | ');
  }

  getKeyFromTab(tab: SupplierDetailTabs) {
    for (const key in SupplierDetailTabs) {
      if (Object.prototype.hasOwnProperty.call(SupplierDetailTabs, key)) {
        if (SupplierDetailTabs[key] === tab) {
          return key;
        }
      }
    }
  }

  onItemClick(e) {
    if (this.hasPendingChanges) {
      this.attemptBreadCrumbNavigatePendingEdits(e);
    } else {
      this._store.dispatch(attemptCancelEdit());
      this._store.dispatch(clickedKendoBreadCrumbs({ item: e }));
    }
  }

  public updateSupplierStatus() {
    if (this.editMode && this.hasPendingChanges) {
      this.cancel();
    } else {
      if (this.supplierStatusActive) {
        this.statusChangeModalSubmitButtonName = 'Discontinue';
        this.statusChangeHeader = 'Confirm Discontinue Supplier';
        this.statusChangeModalMessage = `You are about to discountinue this supplier.`;
      } else {
        this.statusChangeModalSubmitButtonName = 'Activate';
        this.statusChangeHeader = 'Confirm Activate Supplier';
        this.statusChangeModalMessage = `You are about to reactivate this supplier.`;
      }
      this._store.dispatch(showSupplierStatusChangeModal());
    }
  }
  //#endregion

  public saveSupplierStatusChange(e) {
    const statusUpdateNotes = new Array<string>();
    if (e.ticket && e.ticket.length > 0) {
      statusUpdateNotes.push(`Ticket: ${e.ticket}`);
    }

    if (e.requestReason && e.requestReason.length > 0) {
      statusUpdateNotes.push(`Reason: ${e.requestReason}`);
    }

    const payload = {
      auditNotes: statusUpdateNotes.join(' | '),
      isActive: e.isActive,
      status: e.status,
      esn: this.supplier.esn
    };
    this._store.dispatch(supplierStatusChangeUpdate(payload));
    this.cancel();
  }

  getSupplierTag(status) {
    if (!status) {
      return '';
    }
    const hasAcq = status.indexOf('Acquisition') !== -1;
    const hasSup = status.indexOf('Manufacturer') !== -1;
    if (hasAcq) {
      return 'Discontinued - Name/Acq.';
    } else if (hasSup) {
      return 'Discontinued - Manuf.';
    } else {
      return status;
    }
  }

  isSupplierInDraft() {
    return isDraft(this.supplier);
  }

  moreOptionSelected(option: OptionMenuItem) {
    this._store.dispatch(hideDocumentsAlertMessage());
    switch (option.data) {
      case SupplierAction.ActivateSupplier:
      case SupplierAction.DiscontinueSupplier: {
        this.updateSupplierStatus();
        break;
      }
      case SupplierAction.ApproveGeneralTab:
      case SupplierAction.RejectGeneralTab: {
        this._store.dispatch(showGeneralTabOnBoardingStatusModal({ onboardingStatusChangeAction: option.data }));
        break;
      }
      case SupplierAction.DeleteSupplier: {
        this._store.dispatch(showDeleteSupplierModal());
        break;
      }
      case SupplierAction.RefreshTerms: {
        this._store.dispatch(clickTermsRefreshButton());
        break;
      }
      case SupplierAction.ExportDPI: {
        this._store.dispatch(userClickExportSupplierDataButton({ esn: this.supplier.esn }));
        break;
      }
    }
  }

  onBoardingStatusAlertMessage() {
    if (!isDraft(this.supplier)) {
      return null;
    }
    switch (this.currentTab) {
      case SupplierDetailTabs.general: {
        return this.onBoardingStatusAlertMessageForGeneralTab();
      }
      case SupplierDetailTabs.legalSignatures: {
        return this.onBoardingStatusAlertMessageForLegalSignaturesTab();
      }
      case SupplierDetailTabs.supplierTerms: {
        if (!isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general) && isInvitedSupplier(this.supplier)) {
          return {
            type: AlertTypes.Information,
            message: Constants.TermsOnboardingMessage,
          };
        }
        break;
      }
      case SupplierDetailTabs.documents: {
        return this.onBoardingStatusAlertMessageForDocumentsTab();
      }
    }
  }

  onBoardingStatusAlertMessageForGeneralTab() {
    if (isTabInReview(this.onboardingStatuses, SupplierDetailTabs.general)) {
      return {
        type: AlertTypes.Information,
        message: 'Supplier Legal Name, Headquarter Address, and W9 Form are ready for KeHE review.',
      };
    }
    if (isTabRejected(this.onboardingStatuses, SupplierDetailTabs.general)) {
      return {
        type: AlertTypes.Information,
        message: 'Revisions have been requested for the Legal Name / W9. See the Revision Notes for details.',
      };
    }
  }

  onBoardingStatusAlertMessageForLegalSignaturesTab() {
    if (!this.editMode) {
      if (!isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general)) {
        return {
          type: AlertTypes.Information,
          message: Constants.DocuSignBeingPreparedMessage,
        };
      }
      if (this.supplier?.docuSignData?.status === DocusignStatus.sent) {
        return {
          type: AlertTypes.Information,
          message: Constants.DocuSignSendMessage,
        };
      }
    }
  }

  onBoardingStatusAlertMessageForBrokerTab() {
    if (this.editMode && this.hasBrokerFormWarning) {
      return {
        type: AlertTypes.Warning,
        message: 'You have recommended fields below to review.',
      };
    }
  }

  onBoardingStatusAlertMessageForDocumentsTab() {
    switch (this.documentTabLiveStatus) {
      case OnboardingTabStatuses.NotSubmitted: {
        return {
          type: AlertTypes.Warning,
          message: Constants.DocumentsTabNotSubmittedAlert,
        };
      }
      case OnboardingTabStatuses.InReview: {
        return {
          type: AlertTypes.Information,
          message: Constants.DocumentsTabInReviewAlert,
        };
      }
      default:
        return null;
    }
  }

  showRevisionNotes() {
    return this.currentTab === SupplierDetailTabs.general && isTabRejected(this.onboardingStatuses, SupplierDetailTabs.general);
  }

  revisionNotesClicked() {
    this._store.dispatch(showGeneralTabOnBoardingStatusModal({ onboardingStatusChangeAction: SupplierAction.ShowRevisionNotes }));
  }

  showNewContractWarning() {
    return this.editMode && isDraft(this.supplier) && isInvitedSupplier(this.supplier)
      && this.currentTab === SupplierDetailTabs.supplierTerms;
  }

  downloadAddendumAlertClicked() {
    this._store.dispatch(downloadNewContractTemplate());
  }

  purchasingOptionsMenuOptionSelected(optionMenuItem: OptionMenuItem): void {
    this._purOpsFacade.headerEllipsisMenuOptionSelected(optionMenuItem);
  }
}
