import { createSelector } from '@ngrx/store';
import { getConnectBiMenu, getDocumentsMenu, getProductsMenu, getPurchasingMenu, getSalesMenu, getUsersMenu, getUtilitiesMenu, getV1LegacyMenu } from './util';
import { KeheNavigationItem } from '@kehe/phoenix-top-navigation-panel';
import { FeatureFlags } from '@app/feature-flag';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

export const selectProductsNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  AuthenticationSelectors.selectUserGroups,
  selectIsFeatureFlagOn(FeatureFlags.ProhibitRestrict.key),
  selectIsFeatureFlagOn(FeatureFlags.ProhibitRestrictRelease1.key),
  (userActions, userGroups, isProhibitRestrictFeatureFlagEnabled, isProhibitRestrictRelease1Enabled) => getProductsMenu(userActions, userGroups, isProhibitRestrictFeatureFlagEnabled, isProhibitRestrictRelease1Enabled)
);

export const selectSalesNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  AuthenticationSelectors.selectUserGroups,
  selectIsFeatureFlagOn(FeatureFlags?.EnterpriseSODCustomerManagement?.key),
  selectIsFeatureFlagOn(FeatureFlags?.PromotionsEligibilityPage?.key),
  selectIsFeatureFlagOn(FeatureFlags?.MyLists?.key),
  selectIsFeatureFlagOn(FeatureFlags?.ManageCustomPromotions?.key),
  (
    userActions: string[],
    userGroups,
    isEnterpriseSODCustomerManagementFeatureFlagEnabled,
    isPromotionsEligibilityPageEnabled,
    isMyListsFfOn,
    isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled,
  ) =>
    getSalesMenu(
      userActions,
      userGroups,
      isEnterpriseSODCustomerManagementFeatureFlagEnabled,
      isPromotionsEligibilityPageEnabled,
      isMyListsFfOn,
      isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled,
    )
);

export const selectDocumentsNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  (userActions: string[]) => getDocumentsMenu(userActions)
);

export const selectPurchasingNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  AuthenticationSelectors.selectUserGroups,
  selectIsFeatureFlagOn(FeatureFlags.PromotionalRoadmapAccess.key),
  selectIsFeatureFlagOn(FeatureFlags.RoadmapNationalDealsAccess.key),
  (userActions: string[], userGroups, promoRoadmapFF, nationalDealsRoadmapFF) =>
    getPurchasingMenu(userActions, userGroups, promoRoadmapFF, nationalDealsRoadmapFF)
);

export const selectConnectBiNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  (userActions: string[]) => getConnectBiMenu(userActions)
);

export const selectUtilitiesNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  selectIsFeatureFlagOn(FeatureFlags?.CEManageCarousels?.key),
  selectIsFeatureFlagOn(FeatureFlags?.ManageAnnouncements?.key),
  selectIsFeatureFlagOn(FeatureFlags?.ShowAssetManager?.key),
  (userActions: string[],
    isCEManageCarouselsFeatureFlagEnabled: boolean,
    isManageAnnouncementsFeatureFlagEnabled: boolean,
    isShowAssetsFeatureFlagEnabled: boolean,
  ) =>
    getUtilitiesMenu(userActions, isCEManageCarouselsFeatureFlagEnabled, isManageAnnouncementsFeatureFlagEnabled, isShowAssetsFeatureFlagEnabled)
);

export const selectUsersNavigationItem = createSelector(
  AuthenticationSelectors.selectUserActions,
  (userActions: string[]) => getUsersMenu(userActions)
);

export const selectLegacyNavigationItem = createSelector(
  AuthenticationSelectors.selectUserGroups,
  (userGroups) => getV1LegacyMenu(userGroups)
);

export const selectNavigationItems = createSelector(
  selectProductsNavigationItem,
  selectSalesNavigationItem,
  selectDocumentsNavigationItem,
  selectPurchasingNavigationItem,
  selectConnectBiNavigationItem,
  selectUtilitiesNavigationItem,
  selectUsersNavigationItem,
  selectLegacyNavigationItem,
  (
    productsNavigationItem: KeheNavigationItem,
    salesNavigationItem: KeheNavigationItem,
    documentsNavigationItem: KeheNavigationItem,
    purchasingNavigationItem: KeheNavigationItem,
    connectBiNavigationItem: KeheNavigationItem,
    utilitiesNavigationItem: KeheNavigationItem,
    usersNavigationItem: KeheNavigationItem,
    legacyNavigationItem: KeheNavigationItem
  ) => {
    return [
        new KeheNavigationItem('Home', '/', { exact: true }),
        productsNavigationItem,
        salesNavigationItem,
        documentsNavigationItem,
        purchasingNavigationItem,
        connectBiNavigationItem,
        utilitiesNavigationItem,
        usersNavigationItem,
        legacyNavigationItem,
    ].filter((navItem) => navItem);
  }
);

