import { SupplierFieldType } from '../models/supplier-field-type';

export class SupplierDetailsConstants {

  public static MCBExceptionCodeTooltip = 'If Both, MCB Fee is the greater of the two.';
  public static PublicationListingFeesTooltip = '<strong>Publications Include:</strong>' +
    '<ul class="pl-3 mb-0">' +
    '<li>Monthly Buying Guide</li>' +
    '<li>Custom Natural Solutions</li>' +
    '<li>Source Catalog</li>' +
    '<li>Marketing Monthly</li>' +
    '<li>My Store My Flier</li>' +
    '<li>Everyday Catalog</li>' +
    '</ul>';

  public static MCBExceptionCode = {
    Both: 'Both',
    Dollar: 'Dollar',
    Percentage: 'Percentage',
    BothKey: 'B',
    DollarKey: 'D',
    PercentageKey: 'P',
  };

  public static MCBExceptionCodeList: { name, value }[] = [
    {
      name: SupplierDetailsConstants.MCBExceptionCode.Both,
      value: SupplierDetailsConstants.MCBExceptionCode.BothKey,
    },
    {
      name: SupplierDetailsConstants.MCBExceptionCode.Dollar,
      value: SupplierDetailsConstants.MCBExceptionCode.DollarKey,
    },
    {
      name: SupplierDetailsConstants.MCBExceptionCode.Percentage,
      value: SupplierDetailsConstants.MCBExceptionCode.PercentageKey,
    }
  ];

  public static SupplierFields: { field, name, type: SupplierFieldType }[] = [
    {
      field: 'name',
      name: 'Name',
      type: SupplierFieldType.input
    },
    {
      field: 'supplierDevelopmentManager.code',
      name: 'Supplier Development Manager',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'categoryManager.code',
      name: 'Category Manager',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'headquarterAddress.country',
      name: 'Headquarter Country',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'headquarterPhone',
      name: 'Headquarter Phone',
      type: SupplierFieldType.input
    },
    {
      field: 'headquarterEmail',
      name: 'Headquarter Email',
      type: SupplierFieldType.input
    },
    {
      field: 'headquarterAddress.street1',
      name: 'Headquarter Address 1',
      type: SupplierFieldType.input
    },
    {
      field: 'headquarterAddress.city',
      name: 'Headquarter City',
      type: SupplierFieldType.input
    },
    {
      field: 'headquarterAddress.state',
      name: 'Headquarter State',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'headquarterAddress.zipCode',
      name: 'Headquarter Zip',
      type: SupplierFieldType.input
    },
    {
      field: 'tradingPartnerId',
      name: 'Trading Partner ID',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'supplierType',
      name: 'Supplier Type',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.contractDate',
      name: 'Contract Date',
      type: SupplierFieldType.date
    },
    {
      field: 'terms.changeDate',
      name: 'Change Date',
      type: SupplierFieldType.date
    },
    {
      field: 'terms.dueDateCalculation',
      name: 'Due Date Calculation',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.priceChangeNoticeInDays',
      name: 'Price Change Notification',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.acceptsNewItemSetupFee',
      name: 'New Product Setup Fee',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.acceptsCutCaseFee',
      name: 'Cut Cases',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.acceptsWarehouseOutOfDateCharge',
      name: 'Warehouse Spoils',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.shelfLifePercentage',
      name: 'Shelf Life Commitment',
      type: SupplierFieldType.input
    },
    {
      field: 'terms.paymentTerms.code',
      name: 'Payment Terms',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.paymentMethodCode',
      name: 'Payment Method',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.spoils.spoilsType',
      name: 'Spoils',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.hasRetailerDiscontinuedProgram',
      name: 'Retailer DISC',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasKeheDiscount',
      name: 'KeHE DISC',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasRetailerReclamation',
      name: 'Retailer Reclamation',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasLumpingFee',
      name: 'Lumping Fees',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasFillRateFee',
      name: 'Fill Rate Fee',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasThirtyPercentOrderCoverage',
      name: 'Order Overage 30%',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasPalletCharge',
      name: 'Pallet Charges',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.palletChargeAmount',
      name: 'Pallet Charge Amount',
      type: SupplierFieldType.input
    },
    {
      field: 'terms.promotions.acceptsManufacturerChargeBackFee',
      name: 'MCB Processing Fee',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.promotions.hasPublicationListingFee',
      name: 'Publication Listing Fee',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.promotions.hasExtraPerformance',
      name: 'Extra Performance',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.promotions.marketingAllowance.code',
      name: 'KeHE CONNECT BI Program',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.promotions.manufacturerChargeBackExceptionCode',
      name: 'MCB Exception Code',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.spoils.allowancePercentage',
      name: 'Spoilage Allowance Percent',
      type: SupplierFieldType.input
    },
    {
      field: 'terms.hasTermsFormExemption',
      name: 'Terms Form Exemption',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasProcurementHold',
      name: 'Payment Hold',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.acceptsImagingCharge',
      name: 'Product Imaging Charge',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.acceptsIntroAllowance',
      name: 'New Product Intro Allowance',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.specialEventTermsSeasonalCode',
      name: 'Special Event Terms (Seasonal)',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.specialEventTermsNewWarehouseOpeningCode',
      name: 'Special Event Terms (New Warehouse Opening)',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.hasStatementAudit',
      name: 'Audits',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasPromoAllow',
      name: 'Promotion Allowance Audits',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.hasPromoPriceProtect',
      name: 'Promotional Price Protection Audits',
      type: SupplierFieldType.radio
    },
    {
      field: 'terms.auditTimeFrameCode',
      name: 'Audit Timeframe',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.promotions.freeProductsProgramCode',
      name: 'Placement Allowances (Free Fill)',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'terms.promotions.freeProductHandlingChargeCode',
      name: 'Free Fill Handling Charge',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'remitName',
      name: 'Remit Name',
      type: SupplierFieldType.input
    },
    {
      field: 'remittanceAddress.country',
      name: 'Remit Country',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'remitPhone',
      name: 'Remit Phone',
      type: SupplierFieldType.input
    },
    {
      field: 'remitEmail',
      name: 'Remit Email',
      type: SupplierFieldType.input
    },
    {
      field: 'remittanceAddress.street1',
      name: 'Remit Address 1',
      type: SupplierFieldType.input
    },
    {
      field: 'remittanceAddress.city',
      name: 'Remit City',
      type: SupplierFieldType.input
    },
    {
      field: 'remittanceAddress.state',
      name: 'Remit State',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'remittanceAddress.zipCode',
      name: 'Remit Zip',
      type: SupplierFieldType.input
    },
    {
      field: 'temperatureClass',
      name: 'Temperature Class',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'buyerCode',
      name: 'Buyer',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'buyerRegion',
      name: 'Buyer Region',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'buyerEmail',
      name: 'Buyer Email',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.deliveryMethodCode',
      name: 'Delivery Method',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'delivery.pickupAddress.country',
      name: 'Pickup Country',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'delivery.pickupPhone',
      name: 'Pickup Phone',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupEmail',
      name: 'Pickup Email',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupAddress.street1',
      name: 'Pickup Address 1',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupAddress.city',
      name: 'Pickup City',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupAddress.state',
      name: 'Pickup State',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'delivery.pickupAddress.zipCode',
      name: 'Pickup Zip',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.deliveredLeadTimes.readyTime',
      name: 'Supplier Ready Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.deliveredLeadTimes.transitTime',
      name: 'KeHE Transit Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.deliveredLeadTimes.totalTime',
      name: 'Total Lead Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupLeadTimes.readyTime',
      name: 'Supplier Ready Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupLeadTimes.transitTime',
      name: 'KeHE Transit Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupLeadTimes.totalTime',
      name: 'Total Lead Time',
      type: SupplierFieldType.input
    },
    {
      field: 'delivery.pickupAddress.name',
      name: 'Pickup Name',
      type: SupplierFieldType.input
    },
    {
      field: 'poTransmissions.poFax.fax',
      name: 'PO Fax',
      type: SupplierFieldType.input
    },
    {
      field: 'poTransmissions.poOrderMinimum.unit',
      name: 'PO Order Type',
      type: SupplierFieldType.dropdown
    },
    {
      field: 'poTransmissions.poOrderMinimum.amount',
      name: 'PO Order Min',
      type: SupplierFieldType.input
    },
  ];

  public static RequestReasonMaxCharLength: number = 50;
}
