export enum SupplierDetailTabs {
  general = 'General',
  users = 'Users',
  contacts = 'Contacts',
  brands = 'Brands',
  broker = 'Broker',
  locations = 'Locations',
  legalSignatures = 'Legal & Signatures',
  performance = 'Performance',
  documents = 'Documents',
  supplierTerms = 'Supplier Terms',
  supplyChain = 'Supply Chain',
  productGroups = 'Product Groups',
  purchasingOptions = 'Purchasing Options',
  history = 'History',
  purchasingInfo = 'Purchasing Info',
  associatedSuppliers = 'Associated Suppliers',
}
