import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupplierDetailPageComponent } from './pages/supplier-detail-page/supplier-detail-page.component';
import { CanDeactivateGuardService } from '../../services/can-deactivate-guard.service';
import { GeneralTabComponent } from './components/general-tab/general-tab.component';
import { TermsTabComponent } from './components/terms-tab/terms-tab.component';
import { HistoryTabComponent } from './components/history-tab/history-tab.component';
import { ProductGroupsTabComponent } from './components/product-groups-tab/product-groups-tab.component';
import { ProductGroupsListComponent } from './components/product-groups-list/product-groups-list.component';
import { AddProductGroupFormComponent } from './components/add-product-group-form/add-product-group-form.component';
import { BrandsTabComponent } from './components/brands-tab/brands-tab.component';
import { ContactsTabComponent } from './components/contacts-tab/contacts-tab.component';
import { PurchasingOptionsGuard } from './guards/purchasing-options.guard';
import { DocumentsTabComponent } from './components/documents-tab/documents-tab.component';
import { LegalSignaturesTabComponent } from './components/legal-signatures-tab/legal-signatures-tab.component';
import { PurchasingInfoTabComponent } from './components/purchasing-info-tab/purchasing-info-tab.component';
import { SupplierUsersTabComponent } from '../supplier-users/pages/supplier-users-tab/supplier-users-tab.component';
import { LocationsTabComponent } from './components/locations-tab/locations-tab.component';
import { PerformanceTabComponent } from './components/performance-tab/performance-tab.component';
import { BrokerTabV2Component } from './components/broker-tab-v2/broker-tab-v2.component';
import { BrokerV2Guard } from './guards/broker-v2.guard';
import { AssociatedSupplierTabComponent } from './components/associated-supplier-tab/associated-supplier-tab.component';
import { AssociatedSupplierTabGuard } from './guards/associated-supplier-tab.guard';

const routes: Routes = [
  {
    path: '',
    component: SupplierDetailPageComponent,
    canDeactivate: [CanDeactivateGuardService],
    data: {
      breadcrumb: 'Supplier Detail'
    },
    children: [
      {
        path: 'general',
        component: GeneralTabComponent,
        data: {
          breadcrumb: 'Supplier Detail'
        }
      },
      {
        path: 'users',
        component: SupplierUsersTabComponent,
        data: {
          breadcrumb: 'Users'
        }
      },
      {
        path: 'contacts',
        component: ContactsTabComponent,
        data: {
          breadcrumb: 'Contacts'
        }
      },
      {
        path: 'brands',
        component: BrandsTabComponent,
        data: {
          breadcrumb: 'Brands'
        }
      },
      {
        path: 'broker',
        component: BrokerTabV2Component,
        canActivate: [BrokerV2Guard]
      },
      {
        path: 'associatedSuppliers',
        component: AssociatedSupplierTabComponent,
        canActivate: [AssociatedSupplierTabGuard]
      },
      {
        path: 'locations',
        component: LocationsTabComponent,
      },
      {
        path: 'legalSignatures',
        component: LegalSignaturesTabComponent,
      },
      {
        path: 'purchasingInfo',
        component: PurchasingInfoTabComponent,
      },
      {
        path: 'supplierTerms',
        component: TermsTabComponent,
      },
      {
        path: 'productGroups',
        component: ProductGroupsListComponent,
      },
      {
        path: 'performance',
        component: PerformanceTabComponent,
      },
      {
        path: 'documents',
        component: DocumentsTabComponent,
      },
      {
        path: 'productGroups/:productGroupNumber',
        component: ProductGroupsTabComponent,
        data: {
          breadcrumb: 'Product Group Detail'
        }
      },
      {
        path: 'productGroups/edit',
        component: AddProductGroupFormComponent,
      },
            {
        path: 'purchasing-options',
        redirectTo: 'purchasingOptions',
        pathMatch: 'full'
      },
      {
        path: 'purchasing-options/:id',
        redirectTo: 'purchasingOptions/:id',
        pathMatch: 'full'
      },
      {
        path: 'purchasingOptions',
         loadChildren: () => import('../purchasing-options/purchasing-options.module').then(m => m.PurchasingOptionsWrapperModule),
        canActivate: [PurchasingOptionsGuard]
      },
      {
        path: 'history',
        component: HistoryTabComponent,
      },
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full' ,
      },
      {
        path: '**',
        redirectTo: 'general',
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierDetailRoutingModule { }
